import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import PromptItem from "../../uiElements/PromptItem";
import {GA_EVENTS, PROMPT_ITEMS} from "../../../lib/constants";

const ApiCallToAction =()=>  {
    const [promptContent, setPromptContent] = useState('unAuth');

    const user = useSelector(state => state.userReducer);

    useEffect(()=> {
        setPromptContent(definePromptContent(user.userPermission));
    },[user.userPermission]);

    const definePromptContent = (permission) => {
        switch(true) {
            case permission === 'unAuth' || permission === 'unlimitedActive':
                return permission;
            case permission.includes('Inactive') || permission.includes('free'):
                return 'freeOrInactive';
            default:
                return permission;
        }
    }

    return (
        <section className='prompt__block checker-api__block checker-api__prompt'>
            <PromptItem content={PROMPT_ITEMS['apis'][promptContent]}
                        gaEvent={{category: 'page_internal_link', action: GA_EVENTS.apis.cta[promptContent]}}
            />
        </section>
    );
};

export default ApiCallToAction;