import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {RELEASE_NOTES_BY_PROJECT_ID, ROUTE_PATHS, SEO_TAGS} from "../../../../lib/constants";
import store from "../../../../store";
import {getReleaseNotesByProject} from "../../../../providers/releaseNotesService";
import DOMPurify from "dompurify";
import moment from "moment";
import {Helmet} from "react-helmet";

const ReleaseNotesPageContentComponent = (props) =>  {
    const {history, location} = props;
    const [project, setProject] = useState(null);
    const [projectKey, setProjectKey] = useState(null);
    const [releaseNotesArr, setReleaseNotesArr] = useState([]);
    const [fetchingReleaseNotes, setFetchingReleaseNotes] = useState(false);
    const [fetchedReleaseNotes, setFetchedReleaseNotes] = useState(false);
    const [errorReleaseNotes, setErrorReleaseNotes] = useState(null);

    const releaseNotes = useSelector(state => state.releaseNotesReducer);


    useEffect(() => {
        let key = props.match.params.project;
        if (key) {
            let currProject = RELEASE_NOTES_BY_PROJECT_ID[key];
            if (currProject) {
                setProject(currProject);
                setProjectKey(key)
            } else {
                history.push({
                    pathname: '/' + ROUTE_PATHS['resources']
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.match.params, location.pathname]);

    useEffect(() => {
        if (project && projectKey) {
            store.dispatch(getReleaseNotesByProject('/gitlab/release/notes/' + project.id, project.id, projectKey)).then(()=> {
            }, ()=> {
                setErrorReleaseNotes('An error occurred, unable to fetch the release notes of ' + project.title + '.')
            });
        }
    }, [project, projectKey])

    useEffect(() => {
        if (releaseNotes && projectKey) {
            if (releaseNotes[projectKey] &&  releaseNotes[projectKey].payload && releaseNotes[projectKey].payload.length > 0) {
                setReleaseNotesArr(releaseNotes[projectKey].payload);
                window.scrollTo(0, 0);
            }

            if (releaseNotes[projectKey]) {
                setFetchingReleaseNotes(releaseNotes[projectKey].fetching);

                setFetchedReleaseNotes(releaseNotes[projectKey].fetched);
            }
        }

    }, [releaseNotes, project, projectKey]);

    
    return (
        <section className='release-notes'>
            {projectKey ?
                <Helmet>
                    <title>{SEO_TAGS.releaseNotes[projectKey].title}</title>
                    <meta name="description" content={SEO_TAGS.releaseNotes[projectKey].description}/>
                    <link rel="canonical" href={process.env.PUBLIC_URL + '/' + ROUTE_PATHS['releaseNotes'] + '/' + projectKey}/>
                    <meta property="og:title"  content={SEO_TAGS.releaseNotes[projectKey].title}/>
                    <meta property="og:description" content={SEO_TAGS.releaseNotes[projectKey].description}/>
                    <meta property="og:url" content={process.env.PUBLIC_URL + '/' + ROUTE_PATHS['releaseNotes'] + '/' + projectKey}/>
                    <meta name="twitter:title" content={SEO_TAGS.releaseNotes[projectKey].title}/>
                    <meta name="twitter:description" content={SEO_TAGS.releaseNotes[projectKey].description}/>
                </Helmet>:null
            }
            {project ?
                <h3 className="page__title">{project.title + ' RELEASE NOTES'}</h3>
                :null}
            {releaseNotesArr.length > 0 && !fetchingReleaseNotes ?
                <article>
                    {releaseNotesArr.map((rn)=> {
                        return <div className='release-notes__item'>
                            <h4>{rn.name}</h4>
                            <h5>{moment(new Date(rn.released_at)).format('LL')}</h5>
                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rn.description_html) }}/>
                        </div>
                    })
                    }
                </article>:null}
            {
                releaseNotesArr.length <= 0 && !fetchingReleaseNotes && fetchedReleaseNotes ?
                    <h6>{'There are no release notes available for the current product'}</h6>:null
            }
            {
                fetchingReleaseNotes && !fetchedReleaseNotes ?
                    <div className="spinner loading"/>: null
            }
            {
                !fetchingReleaseNotes && !fetchedReleaseNotes && errorReleaseNotes ?
                    <h6 className='release-notes__error'>
                        {errorReleaseNotes}
                    </h6>: null
            }
        </section>
    )
};

export const ReleaseNotesPageContent= withRouter(ReleaseNotesPageContentComponent);
