import React from "react";
import {Faq} from "../Faq";
import {FAQ_API} from "../../../lib/constants";

const ApiFaq =()=>  {
    return (
        <section className='checker-api__block checker-api__faq'>
            <Faq faqContent={FAQ_API} componentKey='apis-faq-content' />
        </section>
    );
};

export default ApiFaq;