import React, {lazy, useEffect, useRef, useState} from 'react';
import store from "../../../../store";
import {getMessagesTypeCountChartData} from "../../../../providers/chartsService";
import moment from 'moment';
import {useSelector} from "react-redux";

const ResponsiveLine = lazy(() =>
    import("@nivo/line").then(module => ({default: module.ResponsiveLine}))
);

export const MessagesTypeCountChart = (props) =>  {
    const [messagesTypeCountData, setMessagesTypeCountData] = useState([]);
    const [timeSpan, setTimeSpan] = useState(null);
    const [fetchingChart, setFetchingChart] = useState(false);
    const [fetchedChart, setFetchedChart] = useState(false);

    const chart = useSelector(state => state.chartsReducer);

    const message = useSelector(state => state.messageReducer);

    const prevMessage = useRef();

    const theme = {
        background: "transparent",
        axis: {
            fontSize: "10px",
            tickColor: "#ffffff",
            ticks: {
                line: {
                    stroke: "#707070"
                },
                text: {
                    fill: "#ffffff"
                }
            },
            legend: {
                text: {
                    fontSize: "10px",
                    fill: "#ffffff"
                }
            }
        },
        crosshair: {
            line: {
                stroke: '#ffffff',
                strokeWidth: 2,
                strokeOpacity: 0.75,
                strokeDasharray: '6 6'
            }
        },
        grid: {
            line: {
                stroke: "#707070"
            }
        }
    };

    useEffect(()=> {
       fetchChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        if (message && message !== prevMessage.current &&
            ((JSON.stringify(message).includes('"fetched":true') &&
                    !JSON.stringify(message).includes('"fetching":true')) ||
                (JSON.stringify(message).includes("'fetched':true'") &&
                    !JSON.stringify(message).includes("'fetching':true'")) ||
                (JSON.stringify(message).includes('fetched:true') &&
                    !JSON.stringify(message).includes('fetching:true'))
            )) {
                    let timeout = 0;
                    if (message !== prevMessage.current) {
                        prevMessage.current = message;
                        timeout = 800;
                    }
                    setTimeout(() => {
                        fetchChart();
                    }, timeout);
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    useEffect(()=> {
        if (chart.messagesTypeCount) {
            setFetchingChart(chart.messagesTypeCount.fetching);
            setFetchedChart(chart.messagesTypeCount.fetched);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[chart.messagesTypeCount]);

    const fetchChart = () => {
        store.dispatch(getMessagesTypeCountChartData()).then((resp)=> {
            processData(resp.value.data.aggregations)
        }, (err)=> {
        })
    }

    const defineDatesTimespan = (dates) => {
        const maxDate= moment(Math.max.apply(null, dates)).toDate();
        const minDate= moment(Math.min.apply(null, dates)).toDate();
        const seconds = (maxDate.getTime() - minDate.getTime()) / 1000;
        const totalDays = Math.floor(seconds / (3600 * 24));
        const daysSpan = Math.ceil(totalDays / 4);
        const timeSpan = daysSpan > 60 ? Math.ceil(daysSpan / 30) : daysSpan;
        let timeUnit = daysSpan > 60 ? ' months' : ' days';
        return 'every ' + timeSpan + timeUnit;
    }

    const processData = (aggr) => {
        const keys = Object.keys(aggr);
        const rawData = aggr[keys[0]].buckets;
        let finalData = [];
        let allDates = [];
        rawData.forEach((msg) => {
            const msgKeys = Object.keys(msg);
            const msgData = msg[msgKeys[2]].buckets;
            const msgId = msg.key === "" ? "Other" : msg.key;
            if (msgData.length > 0) {
                msgData.forEach((msgDate)=> {
                    const dateStr = moment(msgDate.key).format('YYYY-MM-DD');
                    allDates.push(moment(msgDate.key).toDate());
                    if (finalData.filter(m => m.id === msgId).length  <= 0) {
                        let newMsgEntry = {
                            id: msgId,
                            data: [{
                                x: dateStr,
                                y: msgDate.doc_count
                            }]
                        }
                        finalData = [...finalData, newMsgEntry];
                    } else {
                        let existingMsgEntryIdx =  finalData.findIndex(m => m.id === (msg.key === "" ? "Other" : msg.key));
                        let existingMsgData = finalData[existingMsgEntryIdx].data;
                        finalData[existingMsgEntryIdx].data = [...existingMsgData, {x: dateStr, y: msgDate.doc_count}]
                    }
                })
            }
        })
        let datesTickValuesTimeSpan = defineDatesTimespan(allDates);
        setTimeSpan(datesTickValuesTimeSpan);
        setMessagesTypeCountData(finalData);
    }

    return (
        <div className='checker__charts--item checker__charts--type-count'>
            <h4>Your messages of last 30 days</h4>
            { fetchedChart ?
                <React.Fragment>
                    { messagesTypeCountData && messagesTypeCountData.length > 0 && timeSpan ?
                        <ResponsiveLine
                            data={messagesTypeCountData}
                            margin={{ top: 10, right: 80, bottom: 50, left: 25 }}
                            xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: false, precision: 'day'}}
                            xFormat="time:%Y-%m-%d"
                            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false}}
                            curve="linear"
                            lineWidth={2}
                            areaBaselineValue={0}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                format: '%Y-%m-%d',
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                tickValues: timeSpan,
                                // legend: 'Time Scale',
                                // legendOffset: 36,
                                // legendPosition: 'middle'
                            }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 1,
                                tickPadding: 5,
                                tickRotation: 0,
                                // legend: 'Messages Count',
                                // legendOffset: -40,
                                // legendPosition: 'middle'
                            }}
                            colors={{ scheme: 'paired' }}
                            areaOpacity={0.7}
                            borderColor={{ theme: 'background'}}
                            enableGridY={false}
                            enableArea={false}
                            enableSlices={false}
                            useMesh={true}
                            theme={theme}
                            pointSize={7}
                            tooltip={spot => {
                                return <div style={{padding: '7px', backgroundColor: '#ffffff', fontSize: '12px'}}>
                                    <span>{spot.point.data.xFormatted}</span>
                                    <span style={{display: 'flex', alignItems: 'center'}}>
                                    <i style={{width: '8px', height: '8px',
                                        backgroundColor: spot.point.color,
                                        marginRight: '5px',
                                        display: 'block', borderRadius: '100%'
                                    }} />
                                    <span>
                                        {spot.point.serieId}
                                    </span>
                                </span>
                                    <span>
                                    <span>{'actions: '}</span>
                                    <span>{spot.point.data.y}</span>
                                </span>
                                </div>;
                            }}/>
                        :<div className='checker__charts--no-data'>
                            <h5>There are no data to display</h5>
                        </div>}
                </React.Fragment>
                :<React.Fragment>
                    { fetchingChart ?
                        <div className='checker__charts--loading'>
                            <h5>Loading chart...</h5>
                            <div className="spinner loading"/>
                        </div>
                      :<div className='checker__charts--error'>
                            <h5>An error occurred. Unable to fetch chart.</h5>
                        </div>
                    }
                </React.Fragment>
               }
        </div>
    )
}
