import React from 'react';
import {ReleaseNotesPageContent} from './ReleaseNotesPageContent';

const ReleaseNotesLazyHelper =(props)=>  {
    return (
        <React.Fragment>
            <ReleaseNotesPageContent />
        </React.Fragment>
    );
};

export default ReleaseNotesLazyHelper;
