import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {isMobile} from "mobile-device-detect";
import {ROUTE_PATHS, SUBHEADER_VALUES} from "../../../lib/constants";
import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import store from "../../../store";
import {getApiKey, retrieveApiKey} from "../../../providers/finMessageRequest";
import {getUserProfile} from "../../../providers/userService";
import {copyToClipBoard} from "../../../lib/utils";

const ApiInstructionsComponent =(props)=>  {
    const {history, swagData} = props;
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [swag, setSwag] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [hasApiKey, setHasApiKey] = useState(false);
    const [canGetKey, setCanGetKey] = useState(false);
    const [apiKeyError, setApiKeyError] =  useState(null);
    const [apiKeyCopied, setApiKeyCopied] = useState(0);

    const user = useSelector(state => state.userReducer);

    useEffect(()=> {
        setUserLoggedIn(user.userLoggedIn)
    },[user.userLoggedIn]);

    useEffect(()=> {
        if (!(isMobile && !user.userLoggedIn)) {
            setCanGetKey(user.userPermission === 'freeActive' || user.userPermission === 'unlimitedActive');
            if (user.userPermission === 'unAuth') {
                setApiKey(null);
            }
            checkApiKey();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isMobile, user.userLoggedIn, user.userPermission]);

    useEffect(()=> {
       setSwag(swagData)
    },[swagData]);

    const fetchAPIKey = () => {
        store.dispatch(getApiKey()).then((response)=> {
            setApiKey(response.value.data.apiKey);
        }, (error)=> {
            let resp = error.response;
            if (resp.status === 401 || resp.status === 403) {
                store.dispatch(getUserProfile("/me")).then(()=> {
                }, ()=> {
                    history.push({
                        pathname: '/login',
                        state: {from: ROUTE_PATHS['api']}
                    });
                });
            }
            setApiKeyError(true);
        });
    }

    const checkApiKey = ()=> {
        store.dispatch(retrieveApiKey()).then((response)=> {
            setHasApiKey(response.value.status === 204)
        }, ()=> {
            setHasApiKey(false);
        });

    }

    const copyApiKey = () => {
        copyToClipBoard(apiKey);
        setApiKeyCopied(1);
    }

    const downloadJSONfile = () => {
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(swag));
        let dlAnchorElem = document.createElement('a');
        dlAnchorElem.setAttribute("href", dataStr);
        dlAnchorElem.setAttribute("download", "financial-messaging-apis-swagger.json");
        dlAnchorElem.click();
        dlAnchorElem.remove();
    }


    return (
        <section className='checker-api__block checker-api__instructions'>
            <article>
                {isMobile ?
                    <hgroup className='subpage__titles'>
                        <h1 className='page__title'>
                            {SUBHEADER_VALUES.api.title}
                        </h1>
                        <h2 className='page__subtitle'>
                            {SUBHEADER_VALUES.api.description}
                        </h2>
                    </hgroup>
                    :null}
                <h3>HOW TO USE THE APIs</h3>
                <div className='checker-api__how-to'>
                    You need to be a registered user with an active plan
                    <ol>
                        <li>Complete your Sign up (don’t forget to verify your email)</li>
                        <li>Login</li>
                        <li>Generate your API key</li>
                    </ol>
                </div>
                <p className='checker-api__urls'>
                    <span>Live:</span>
                    <span>{process.env.SERVER_URL}</span>
                    <span>Sandbox:</span>
                    <span>{process.env.SERVER_URL + '/sandbox'}</span>
                </p>
                <p className='checker-api__sandbox-spec'>
                    If you use the Sandbox URL, the calls you make don’t count against your “call limit” in the Free Trial Plan.
                </p>
                {!(isMobile && !userLoggedIn) ?
                    <React.Fragment>
                        <div className='checker-api__buttons'>
                            { apiKey && canGetKey  ?
                                <div className='checker-api__copy-key'>
                                    {apiKeyCopied ?
                                        <span className='checker-api__copy-key--copied'
                                              data-flicker={apiKeyCopied}
                                              onAnimationEnd={() => setApiKeyCopied(2)}>
                                                Copied!
                                            </span>:null}
                                    <div className='checker-api__copy-key--text' title={apiKey}>
                                        {apiKey}
                                    </div>
                                    <Button className='checker-api__copy-key--button'
                                            onClick={copyApiKey}>
                                        copy
                                    </Button>
                                </div>: !apiKeyError ?
                                    <Button disabled = {!canGetKey || (user.apiKey ? user.apiKey.fetching : false)}
                                            className='checker-api__button action__btn'
                                            onClick={userLoggedIn ? fetchAPIKey : undefined}>
                                        {(hasApiKey ? 'Regenerate' : 'Generate') + ' your API key'}
                                    </Button> :
                                    <div className='checker-api__copy-key--error'>
                                        <Button className='checker-api__copy-key--retry checker-api__button action__btn'
                                                onClick={()=> {setApiKeyError(false)}}>
                                            RETRY
                                        </Button>
                                    </div>
                            }
                            <Button disabled = {!canGetKey}
                                    className='checker-api__button action__btn'
                                    onClick={userLoggedIn && swag ? downloadJSONfile : undefined}>
                                Download API specs file
                            </Button>
                        </div>
                        {apiKey ?
                            <div className='checker-api__copy-key--note'>
                                This is your API key. Copy it now in a safe place. If you lose it, you can generate a new one.
                            </div>:
                            apiKeyError ?
                                <div className='checker-api__copy-key--note checker-api__copy-key--error'>
                                    {'An error occured while fetching your API key. Please try again later, or contact us at '}
                                    <a href="mailto:finaplo@paymentcomponents.com?subject=Fetching API key issue at Financial Messages Checker&body=">
                                        finaplo@paymentcomponents.com
                                    </a>
                                </div>
                                :
                                <div className='checker-api__copy-key--note'>
                                    In case you’ve lost your API key you can come back and generate a new one, this will make your old one inactive.
                                </div>
                        }
                    </React.Fragment>
                :null}
            </article>
        </section>
    );
};

export const ApiInstructions = withRouter(ApiInstructionsComponent)