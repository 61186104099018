import {makeRequest} from "../api/api-client";

export const getLogsList = (pageSize, pageNumber, orderBy, orderDirection) => {
    let path = '/log/list';
    let params = {
        pageSize: pageSize,
        pageNumber: pageNumber,
        orderBy: orderBy,
        orderDirection: orderDirection
    }

    if (process.env.NODE_ENV === "production" ){
        path = process.env.SERVER_URL + path;
    }

    return makeRequest(path, "GET", null, {params: params}, "GET_LOGS_LIST");
};

export const findLog = (id, level) => {
    let path = '/log/find';
    if (process.env.NODE_ENV === "production" ){
        path = process.env.SERVER_URL + path;
    }
    let params = {id: id, level: level}
    return makeRequest(path, "GET", null, {params: params}, "GET_LOG_" + id.toUpperCase());
};