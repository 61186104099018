import React, {lazy, Suspense, useContext, useEffect, useState} from 'react';
import store from "../../../../../store";
import {Button} from 'react-bootstrap';
import {AppContext} from '../../../../../../App';
import {finMessageRequest} from '../../../../../providers/finMessageRequest';
import {
    UNPARSEABLE_XML,
    VALIDATE_INSTRUCTIONS,
    ROUTE_PATHS,
    CODEMIRROR_REFRESH_TIMEOUT, MSG_HINT
} from '../../../../../lib/constants';
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    checkEmptyStr, checkGenericServerError,
    checkRespData,
    compareTrialsState,
    getRespEls, getResultMessageType,
    sendGaEvent
} from "../../../../../lib/utils";
import AnimateOnChange from "react-animate-on-change";
import {getUserProfile} from "../../../../../providers/userService";
import {checkMessageType, defineGenericMsg} from "../../../../../lib/msgUserPermissions";

const CodeMirror = lazy(() =>
    import("react-codemirror2").then(module => ({default: module.Controlled}))
);

const TranslatorTargetTwoMxContentComponent = (props) => {
    const {selectedMsgSubType, changeRespObject, respObject, userPermission, history, location} = props;
    const [translatorTargetTwoMxMessages, setTranslatorTargetTwoMxMessages] = useState(null);
    const [msgValue, setMsgValue] = useState('');
    const [msgTitle, setMsgTitle] = useState('');
    const {state, dispatch} = useContext(AppContext);
    const [animate, setAnimate] = useState(false);
    const [disableTransBtn, setDisableTransBtn] = useState(false);
    const [showClearBtn, setShowClearBtn] = useState(false);
    const [respObj, setRespObj] = useState(respObject);
    const [visualRes, setVisualRes] = useState(null);
    const [msgIsComment, setMsgIsComment] = useState(false);
    const [textareaError, setTextAreaError] = useState(false);

    const user = useSelector(state => state.userReducer);
    const message = useSelector(state => state.messageReducer);
    const plans = useSelector(state => state.plansReducer);

    import ('../../../../../lib/finMessagesSamples/translator_target_two_mx_tree').then(tree => {
        setTranslatorTargetTwoMxMessages(tree.TRANSLATOR_TARGET_TWO_MX_MESSAGES);
    });

    useEffect(()=> {

        const logData = location && location.state && location.state.logData ? location.state.logData: null;

        if (selectedMsgSubType && translatorTargetTwoMxMessages && translatorTargetTwoMxMessages[selectedMsgSubType] && !logData) {
            setRespObj(null);
            setMsgValue(MSG_HINT + translatorTargetTwoMxMessages[selectedMsgSubType].msg);
            setMsgTitle('TRANSLATE: ' + translatorTargetTwoMxMessages[selectedMsgSubType].title);
            setMsgIsComment(false);
        }

        if (userPermission && !selectedMsgSubType && plans.payload && !logData) {
            let title = userPermission !== 'unAuth' && userPermission !== 'freeInactive' &&
            userPermission !== 'unlimitedInactive' ? VALIDATE_INSTRUCTIONS: '';
            setRespObj(null);
            setMsgTitle(title);
            setMsgIsComment(true);
            const genericMsg = defineGenericMsg(userPermission, plans.payload[0].limit);
            setMsgValue(prevMsgValue => prevMsgValue !== genericMsg ? genericMsg : prevMsgValue );
        }

    }, [translatorTargetTwoMxMessages, selectedMsgSubType, userPermission, location, plans.payload]);

    useEffect(()=> {
        if (message.targetTwoMxTranslate) {
            setDisableTransBtn(message.targetTwoMxTranslate.fetching);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[message.targetTwoMxTranslate]);

    useEffect(()=> {
        setRespObj(respObject)
    },[respObject]);

    useEffect(()=> {
        if (respObj) {
            setVisualRes(getRespEls(respObj));
        }
    },[respObj]);

    useEffect(()=> {
        changeRespObject(null);
        if (user && user.userPermission) {
            setShowClearBtn(user.userPermission !== 'unAuth' && !user.userPermission.endsWith('Inactive'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.userPermission]);

    useEffect(() => {
        let err = location && location.state && location.state.from === "translationError";
        setTextAreaError(err);
        if (err) {
            setTimeout(() => {
                setTextAreaError(false);
            }, 900);
        }

        const logData = location && location.state && location.state.logData ? location.state.logData: null;
        if (logData) {
            const isError = logData.isValid !== 'valid';
            const respObj = {validity: logData.isValid,
                content: isError ? logData.errorResp : logData.message,
                reqType: 'validate',
                hideRespArea: !isError,
                msgType: 'translateTargetTwoMx'
            };
            changeRespObject(respObj)
            setMsgTitle(logData.title);
            setMsgIsComment(false);
            setMsgValue(logData.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location]);
    
    const handleChange  = code => {
        setMsgValue(code);
        setRespObj(null);
    };

    const toggleAnimation = () => {
        setAnimate(true);
        setTimeout(() => {
            setAnimate(false);
        }, 100);
    };

    const sendRequest = (path, reqTypeParam) => {
       let validPermMsg = checkMessageType(msgValue, selectedMsgSubType, 'translatorTargetTwoMx', userPermission);
       if (!validPermMsg) {
           history.push({
               pathname: '/' + ROUTE_PATHS['checker'] + '/translator/target2/mx',
               state: {from: "translationError"}
           });
       } else {
           store.dispatch(finMessageRequest(path, msgValue.replace(MSG_HINT, ''), false, reqTypeParam)).then((response) => {
               toggleAnimation();
               let resp = response.value;
               let trials = resp.headers['x-remaining-trials'];
               if (compareTrialsState(trials, state.remainingTrials)) {
                   dispatch({...state, remainingTrials: trials, animateTrials: true})
               }
               setAnimate(true);
               if (resp.status === 200) {
                   let respType = getResultMessageType(resp.data, 'translatorTargetTwoToMt');
                   let respObject = {
                       validity: 'valid',
                       content: resp.data,
                       reqType: reqTypeParam,
                       respType: respType
                   };
                   changeRespObject(respObject);
               }
           }, (error) => {
               toggleAnimation();
               let resp = error.response;
               let trials = resp.headers['x-remaining-trials'];
               if (compareTrialsState(trials, state.remainingTrials)) {
                   dispatch({...state, remainingTrials: trials, animateTrials: true})
               }
               if (resp.status === 400 || resp.status === 500) {
                   let respObject = {
                       validity: checkEmptyStr(resp.data.message) && checkGenericServerError(resp.data) ? 'invalid__server-error' : 'invalid',
                       content: checkRespData(resp.data),
                       reqType: reqTypeParam,
                       msgType: 'translateTargetTwoMx'
                   };
                   changeRespObject(respObject);
               } else if (resp.status === 412 || resp.status === 429) {
                   history.push('/' + ROUTE_PATHS['checker'] + '/translator/target2/mx');
                   dispatch({...state,
                       noChecksFlag: true,
                       remainingTrials: 0
                   });
               } else if (resp.status === 401) {
                   store.dispatch(getUserProfile("/me")).then(()=> {
                       }, ()=> {
                           history.push({
                               pathname: '/login',
                               state: {from: ROUTE_PATHS['checker'] + '/translator/target2/mx'}
                           });
                   });
               } else {
                   let respObject = {validity: 'error', content: [UNPARSEABLE_XML], reqType: reqTypeParam};
                   changeRespObject(respObject);
               }
            });
        }
    };

    return (
         <div className="msg-content msg-content--translator">
             <p className={!msgIsComment ? 'msg-content__title': ''}>{msgTitle}</p>
             {respObj && visualRes ?
                 <div className={visualRes.bg}>
                     <AnimateOnChange
                         baseClassName='msg-validity_content'
                         animationClassName='msg-validity-animation'
                         animate={animate}>
                         <span className='msg-validity_text'>
                            {visualRes.text}
                         </span>
                         <div className='msg-validity_icon-wrapper'>
                            <span className={visualRes.img}>
                            </span>
                         </div>
                     </AnimateOnChange>
                 </div>
                 :null
             }
             {userPermission === 'unAuth' || userPermission === 'freeInactive' ||
             userPermission === 'unlimitedInactive' || msgIsComment ?
                 <Suspense fallback={<div style={{height:"384px",backgroundColor:"#1E1E1E"}}/>}>
                     <CodeMirror
                         className={'xml-codemirror custom-codemirror unselectable' +
                         (msgIsComment ? ' comment-txt': '') +
                         (textareaError ? ' invalid-txt': '')}
                         value={msgValue}
                         options={{
                             mode: 'xml',
                             theme: 'checker-code',
                             lineNumbers: true,
                             lineWrapping: true,
                             matchBrackets: false,
                             readOnly: 'nocursor',
                             cursorHeight: 0,
                             autoRefresh: true,
                             firstLineNumber: msgValue.includes(MSG_HINT) ? 0 : 1
                         }}
                         editorDidMount={editor => { setTimeout(()=>{ editor.refresh(); }, CODEMIRROR_REFRESH_TIMEOUT);}}
                         onBeforeChange={(editor, data, value) => {
                             return null;
                         }}
                     />
                 </Suspense>:
                 <Suspense fallback={<div style={{height:"384px"}}/>}>
                     <CodeMirror
                         className={"xml-codemirror custom-codemirror" +
                         (msgIsComment ? ' comment-txt': '') +
                         (textareaError ? ' invalid-txt': '')}
                         value={msgValue}
                         options={{
                             mode: 'xml',
                             theme: 'checker-code',
                             lineNumbers: true,
                             lineWrapping: true,
                             matchTags: {bothTags: true},
                             readOnly: false,
                             cursorHeight: 0.8,
                             autoRefresh: true,
                             firstLineNumber: msgValue.includes(MSG_HINT) ? 0 : 1
                         }}
                         editorDidMount={editor => { setTimeout(()=>{ editor.refresh(); }, CODEMIRROR_REFRESH_TIMEOUT);}}
                         onBeforeChange={(editor, data, value) => {
                             handleChange(value)
                         }}
                     />
                 </Suspense>
             }
             <div className="msg-content__bottom">
                 {userPermission && selectedMsgSubType ?
                     <React.Fragment>
                         { showClearBtn ?
                             <Button variant="info"
                                     className="responsive-label"
                                     title='Clear Message'
                                     disabled={!msgValue}
                                     onClick={()=> {
                                         setMsgValue('');
                                     }}>
                                    <span>
                                        {'Clear '}
                                    </span>
                                 <span>
                                        {'Message'}
                                    </span>
                             </Button>
                         : null }
                         <div className={"msg-content__bottom--buttons" + (!showClearBtn ? " full-width": "")}>
                            <Button variant="info"
                                title={'Translate to'}
                                disabled={disableTransBtn}
                                onClick={()=> {
                                    sendRequest('/translator/target2/mx/to/mt', 'translateTargetTwoMx');
                                    sendGaEvent({category: 'demo', action: 'translate-target2-mx'});
                                }}>
                                <span>{'Translate'}</span>
                            </Button>
                         </div>
                     </React.Fragment>
                 :null}
             </div>
        </div>
    );
};

export const TranslatorTargetTwoMxMsgContent = withRouter(TranslatorTargetTwoMxContentComponent);
