import {makeRequest} from "../api/api-client";
import {RELEASE_NOTES_BY_PROJECT_ID} from "../lib/constants";

export const getReleaseNotesByProject = (path, projectId, reducerParam) => {
    if (process.env.NODE_ENV === "production" ){
        path = process.env.SERVER_URL + path;
    }
    let params = {projectId: projectId}
    let reducer = RELEASE_NOTES_BY_PROJECT_ID[reducerParam].reducer;

    return makeRequest(path, "GET", params, {}, "GET_" + reducer + "_RELEASE_NOTES");
};