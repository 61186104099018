import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {MtMsgContent} from './financialMessages/mt/MtMsgContent';
import {MxMsgContent} from './financialMessages/mx/MxMsgContent';
import {SepaMsgContent} from './financialMessages/sepa/SepaMsgContent';
import {ParsedMsgRenderer} from "./rightColumn/ParsedMsgRenderer";
import {TranslatorCbprMtMsgContent} from "./financialMessages/translatorCbpr/TranslatorCbprMtMsgContent";
import {TranslatorCbprMxMsgContent} from "./financialMessages/translatorCbpr/TranslatorCbprMxMsgContent";
import {CbprPlusMsgContent} from "./financialMessages/cbprPlus/CbprPlusMsgContent";
import {TargetTwoMsgContent} from "./financialMessages/targetTwo/TargetTwoMsgContent";
import {SicEuroSicMsgContent} from "./financialMessages/sicEuroSic/SicEuroSicMsgContent";
import {ScripsMepsMsgContent} from "./financialMessages/scripsMeps/ScripsMepsMsgContent";
import {TranslatorTargetTwoMtMsgContent} from "./financialMessages/translatorTargetTwo/TranslatorTargetTwoMtMsgContent";
import {TranslatorTargetTwoMxMsgContent} from "./financialMessages/translatorTargetTwo/TranslatorTargetTwoMxMsgContent";
import {FedNowMsgContent} from "./financialMessages/fedNow/FedNowMsgContent";
import store from "../../../store";
import {getAvailablePlans} from "../../../providers/subscriptionService";
import {ROUTE_PATHS} from "../../../lib/constants";
import {useSelector} from "react-redux";
import EmailComment from "./rightColumn/EmailComment";
import {SideBar} from "./sidebar/SideBar";
import {LogsChartsTabs} from "./tabs/LogsChartsTabs";

const CheckerMainContentComponent = (props) => {
    const {msgType, selectedMsgSubType, userPermission, location, history} = props;
    const [respObj, setRespObj] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const  user = useSelector(state => state.userReducer);
    
    useEffect(() => {
        store.dispatch(getAvailablePlans('/plans'));
        if (history.location.state && history.location.state.logData) {
            let state = { ...history.location.state };
            delete state.logData;
            history.replace({ ...history.location, state });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeRespObj = (obj) => {
        setRespObj(obj);
    };

    useEffect(() => {
        if (location && ((location.state && !location.state.logData) || (!location.state))) {
            setRespObj(null)
        }
    }, [location]);

    useEffect(() =>{
        if (!user.profile.fetching) {
            setUserLoggedIn(user.userLoggedIn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.userLoggedIn]);


    const components = {
        mt: MtMsgContent,
        mx: MxMsgContent,
        sepa: SepaMsgContent,
        translatorCbprMt: TranslatorCbprMtMsgContent,
        translatorCbprMx: TranslatorCbprMxMsgContent,
        cbprPlus: CbprPlusMsgContent,
        targetTwo: TargetTwoMsgContent,
        sicEuroSic: SicEuroSicMsgContent,
        scripsMeps: ScripsMepsMsgContent,
        translatorTargetTwoMt: TranslatorTargetTwoMtMsgContent,
        translatorTargetTwoMx: TranslatorTargetTwoMxMsgContent,
        fedNow: FedNowMsgContent
    };

    const tagName  = (tag) => {
        const TagName = components[tag];
        return <TagName changeRespObject={changeRespObj}
                        respObject={respObj}
                        selectedMsgSubType={selectedMsgSubType}
                        userPermission={userPermission}
        />
    };

    return (
        <React.Fragment>
            <section className='checker__row'>
                <SideBar key='sidebar'/>
                <article className="middle__part">
                    {tagName(msgType)}
                </article>
                <article className="right__col right__part">
                    <div className="right__col--content">
                        <ParsedMsgRenderer respObject={respObj}
                                           userPermission={userPermission}
                        />
                    </div>
                </article>
            </section>
            {userLoggedIn ?
                <section className='checker__row checker__row--logs'>
                    <article className='left__part'>
                    </article>
                    <article className="middle__part">
                        <LogsChartsTabs isLoggedIn={userLoggedIn}
                                   changeRespObject={changeRespObj} />
                    </article>
                    <article className='right__col right__part'>
                        <div className="checker__email-comment--offer__wrapper">
                            <EmailComment />
                            <Link to={ '/' + ROUTE_PATHS['pricing']} className='checker__offer'>
                                <h5 className='checker__offer--hdr-img offer__hdr-img'>
                                    <i className='bg-sprites bg-offer'></i>
                                    <span>BONUS OFFER</span>
                                </h5>
                                <p>
                                    {'You are entitled to a free subscription to an Unlimited Plan if you purchase an SDK library.'}
                                </p>
                            </Link>
                        </div>
                    </article>
                </section>
            :null}
        </React.Fragment>
    );
};

export const CheckerMainContent = withRouter(CheckerMainContentComponent);
