import React from "react";
import QuestionMailTo from "../../uiElements/QuestionMailTo";

const ApiQuestion =()=>  {
    return (
        <section className='question__container checker-api__question'>
            <QuestionMailTo subject='About FINaplo Financial Services APIs'/>
        </section>
    );
};

export default ApiQuestion;