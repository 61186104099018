import React from 'react';
import {PricingPageContent} from './PricingPageContent';

const PricingLazyHelper =(props)=>  {
    return (
        <React.Fragment>
            <PricingPageContent />
        </React.Fragment>
    );
};

export default PricingLazyHelper;