import {makeRequest} from "../api/api-client";



export const getAvailablePlans = (path) => {
    if (process.env.NODE_ENV === "production" ){
        path = process.env.SERVER_URL + path;
    }
    return makeRequest(path, "GET", null, {}, "GET_PLANS");
};

export const fetchPayment = (path, obj) => {
    if (process.env.NODE_ENV === "production") {
        path = process.env.SERVER_URL + path;
    }

    const config = {headers: {'Content-Type': 'application/json'}};
    return makeRequest(path, "POST", obj, config, "FETCH_PAYMENT");
};
