import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {ROUTE_PATHS} from "../../../../lib/constants";



function AcceptTerms(props) {
    const {type, handleCheck, termsChecked} = props;
    const [termsAccepted, setTermsAccepted] = useState(false);

    useEffect(() => {
        setTermsAccepted(termsChecked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[termsChecked]);

    return (
        <div className={"auth__register--terms auth__register--terms__from-" + type }>
            <input type="checkbox" id="auth__register--terms_checkbox" name="auth__register--terms_checkbox"
                   value={termsAccepted}
                   onChange={handleCheck}/>
            <label htmlFor={'auth__register--terms_checkbox'}>
                <span className="auth__register--terms_txt">I’ve read and accept the
                </span>
            </label>
            <Link
                to={{pathname: '/' + ROUTE_PATHS['terms'], state: {from: type}}}
                className="auth__register--terms__btn">
                Terms of Service
            </Link>
        </div>
    );
};

export default AcceptTerms;
