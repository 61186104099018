import React, {useState} from 'react';
import {MESSAGING_LIBRARIES_DOWNLOAD, FIN_MESSAGES_CONTENT, ROUTE_PATHS, GA_EVENTS} from "../../../lib/constants";
import {Link} from "react-router-dom";
import {sendGaEvent} from "../../../lib/utils";


const MessagesSpecifications =()=> {
    const [visibleTextsIds, setVisibleTextsIds] = useState({});

    const toggleMoreInfo = (btnId) => {
        setVisibleTextsIds({...visibleTextsIds, [btnId]: !visibleTextsIds[btnId]})
    }

    return (
        <div className='fin-messages__msg-types--content'>
            <article>
                <div className='fin-messages__msg-types--specifications'>
                    {FIN_MESSAGES_CONTENT.messagesSpec.map((msg, idx)=> {
                        return  <div className={'fin-messages__msg-types--item' + (visibleTextsIds['toggle-txt-btn-' + idx] ? ' full-height' : ' min-height')}
                                        key={'fin-messages-msg-types-specification-' + idx}>
                                    <h5 id={msg.id}>
                                        <span>
                                            {msg.title}
                                        </span>
                                        <span>
                                            <span>
                                                {'View on '}
                                            </span>
                                            <a href={msg.link}
                                               rel="noopener noreferrer"
                                               target="_blank"
                                               onClick={() => {
                                                   sendGaEvent({
                                                           category: 'page_external_link',
                                                           action: GA_EVENTS.finMsgs.github[msg.ga]
                                                       }
                                                   )
                                               }}>
                                                <i className='bg-sprites bg-github'></i>
                                            </a>
                                        </span>
                                    </h5>
                                    <div className='fin-messages__msg-types--paragraphs'>
                                        {msg.text.map((p, idx)=> {
                                            return <p key={'fin-msg-par-' + idx}>
                                                        {p}
                                                    </p>
                                        })}
                                    </div>
                                    <div className='fin-messages__msg-types--toggle__wrapper'>
                                        <button key={'toggle-txt-btn-' + idx}
                                                id={'toggle-txt-btn-' + idx}
                                                className={'fin-messages__msg-types--toggle__txt-btn'}
                                                onClick={()=> {toggleMoreInfo('toggle-txt-btn-' + idx)}}>
                                            <span>
                                                {visibleTextsIds['toggle-txt-btn-' + idx] ? 'Less...' : 'More...'}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                    })}
                </div>
                <div className='fin-messages__msg-types--samples'>
                    <Link to={'/' + MESSAGING_LIBRARIES_DOWNLOAD}
                        className='fin-messages__msg-types--samples__sdks'
                      onClick={()=> {
                          sendGaEvent({category: 'page_internal_link', action: GA_EVENTS.finMsgs.sdks}
                          )}}>
                        Download demo SDKs
                    </Link>
                    <Link className='fin-messages__msg-types--samples__apis'
                          to={'/' + ROUTE_PATHS['api']}
                          onClick={()=> {
                              sendGaEvent({category: 'page_internal_link', action: GA_EVENTS.finMsgs.tryout}
                              )}}>
                        <span>
                            or tryout the Libraries capabilities via our API Free Trial
                        </span>
                        <i></i>
                    </Link>
                </div>
            </article>
        </div>
    );
}

export default MessagesSpecifications;
