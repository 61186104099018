import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import store from "../../../../../store";
import {loginUserLinkedin} from "../../../../../providers/authService";
import {sendGaEvent} from "../../../../../lib/utils";
import {GA_EVENTS} from "../../../../../lib/constants";


const LoginLinkedinComponent = (props) =>  {
    const {termsChecked, gaEvent} = props;
    const [enabledBtn, setEnabledBtn] = useState(false);


    useEffect(() => {
        setEnabledBtn(termsChecked)
    }, [termsChecked]);

    const loginUserWithLinkedin =(e)=> {
        e.preventDefault();
        sendGaEvent({category: 'auth_action', action: GA_EVENTS.auth[gaEvent].linkedin})
        store.dispatch(loginUserLinkedin('/linkedIn/login')).then((response)=> {
            window.open(response.value.data, '_self')
        }, (err)=> {

        });
    }

    return (
        <React.Fragment>
            <div className='auth__alt'>
                <span>OR</span>
            </div>
            <button className='auth__linkedin--btn'
                    disabled={!enabledBtn}
                    onClick={(e)=> {loginUserWithLinkedin(e)}}>
                <img className="bg-linkedin-btn" alt="login with linkedin button"
                     src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUAAAAAoAQMAAAC1jrFqAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABVJREFUeNpjYBgFo2AUjIJRMApoBwAGaAABSDoDZwAAAABJRU5ErkJggg=="/>
            </button>
        </React.Fragment>
    );
};

export const LoginLinkedin = withRouter(LoginLinkedinComponent);