const FREE_PLAN_DURATION = "7";

const UNLIMITED_PLAN_DURATION = "30";

export const MT_MESSAGES_KEYS = ["101", "102", "103", "104", "105", "107", "110", "111", "112", "190", "191", "192", "195", "196", "198", "199", "200", "201", "202", "202cov", "203", "204", "205", "205cov", "210", "290", "291", "292", "295", "296", "298", "299", "300", "304", "305", "306", "320", "321", "330", "340", "341", "350", "360", "361", "362", "364", "365", "370", "380", "381", "390", "391", "392", "395", "396", "398", "399", "400", "410", "412", "416", "420", "422", "430", "450", "455", "456", "490", "491", "492", "495", "496", "498", "499", "500", "501", "502", "503", "504", "505", "506", "507", "508", "509", "510", "513", "514", "515", "516", "517", "518", "519", "524", "526", "527", "530", "535", "536", "537", "538", "540", "541", "542", "543", "544", "545", "546", "547", "548", "549", "558", "564", "565", "566", "567", "568", "569", "575", "576", "578", "581", "586", "590", "591", "592", "595", "596", "598", "599", "600", "601", "604", "605", "606", "607", "608", "620", "670", "671", "690", "691", "692", "695", "696", "698", "699", "700", "701", "705", "707", "708", "710", "711", "720", "721", "730", "732", "734", "740", "742", "744", "747", "750", "752", "754", "756", "759", "760", "761", "765", "767", "768", "769", "775", "785", "786", "787", "790", "791", "792", "795", "796", "798", "799", "801", "802", "890", "891", "892", "895", "896", "898", "899", "900", "910", "920", "935", "940", "941", "942", "950", "970", "971", "972", "973", "985", "986", "990", "991", "992", "995", "996", "998", "999"];

export const MX_MESSAGES_KEYS = [
    "acmt.001.001.07",
    "acmt.002.001.07",
    "acmt.003.001.07",
    "acmt.004.001.05",
    "acmt.005.001.05",
    "acmt.006.001.06",
    "auth.008.001.02",
    "auth.009.001.02",
    "auth.010.001.01",
    "auth.011.001.01",
    "camt.003.001.07",
    "camt.004.001.08",
    "camt.005.001.08",
    "camt.006.001.08",
    "camt.007.001.08",
    "camt.008.001.08",
    "camt.009.001.07",
    "camt.010.001.08",
    "camt.011.001.07",
    "camt.012.001.07",
    "camt.013.001.04",
    "camt.014.001.04",
    "camt.015.001.04",
    "camt.016.001.04",
    "camt.017.001.04",
    "camt.018.001.05",
    "camt.019.001.07",
    "camt.020.001.04",
    "camt.021.001.06",
    "camt.023.001.07",
    "camt.024.001.06",
    "camt.025.001.05",
    "camt.026.001.07",
    "camt.027.001.07",
    "camt.028.001.09",
    "camt.029.001.09",
    "camt.030.001.05",
    "camt.031.001.06",
    "camt.032.001.04",
    "camt.033.001.06",
    "camt.034.001.06",
    "camt.035.001.05",
    "camt.036.001.05",
    "camt.037.001.07",
    "camt.038.001.04",
    "camt.039.001.05",
    "camt.040.001.04",
    "camt.041.001.04",
    "camt.042.001.04",
    "camt.043.001.04",
    "camt.044.001.03",
    "camt.045.001.03",
    "camt.046.001.03",
    "camt.046.001.04",
    "camt.046.001.05",
    "camt.047.001.06",
    "camt.048.001.05",
    "camt.049.001.05",
    "camt.050.001.05",
    "camt.051.001.05",
    "camt.052.001.08",
    "camt.053.001.08",
    "camt.054.001.08",
    "camt.055.001.08",
    "camt.056.001.08",
    "camt.057.001.06",
    "camt.058.001.06",
    "camt.059.001.06",
    "camt.060.001.05",
    "camt.069.001.03",
    "camt.070.001.04",
    "camt.071.001.03",
    "camt.086.001.03",
    "camt.087.001.06",
    "colr.003.001.04",
    "colr.004.001.04",
    "colr.005.001.04",
    "colr.006.001.04",
    "colr.007.001.05",
    "colr.008.001.05",
    "colr.009.001.04",
    "colr.010.001.04",
    "colr.011.001.04",
    "colr.012.001.04",
    "colr.013.001.04",
    "colr.014.001.04",
    "colr.015.001.04",
    "colr.016.001.03",
    "pacs.002.001.10",
    "pacs.003.001.08",
    "pacs.004.001.09",
    "pacs.007.001.09",
    "pacs.008.001.08",
    "pacs.009.001.08",
    "pacs.010.001.03",
    "pacs.028.001.03",
    "pain.001.001.09",
    "pain.002.001.10",
    "pain.007.001.09",
    "pain.008.001.08",
    "pain.009.001.06",
    "pain.010.001.06",
    "pain.011.001.06",
    "pain.012.001.06",
    "pain.017.001.02",
    "pain.018.001.02",
    "reda.001.001.04",
    "reda.002.001.04",
    "reda.004.001.03",
    "reda.005.001.03",
    "seev.001.001.05",
    "seev.002.001.05",
    "seev.003.001.05",
    "seev.004.001.05",
    "seev.005.001.05",
    "seev.006.001.05",
    "seev.007.001.05",
    "seev.008.001.05",
    "seev.031.001.09",
    "seev.032.001.06",
    "seev.033.001.09",
    "seev.034.001.10",
    "seev.035.001.10",
    "seev.036.001.10",
    "seev.037.001.10",
    "seev.038.001.05",
    "seev.039.001.09",
    "seev.040.001.08",
    "seev.041.001.09",
    "seev.042.001.08",
    "seev.044.001.09",
    "semt.001.001.02",
    "semt.002.001.10",
    "semt.003.001.10",
    "semt.004.001.02",
    "semt.005.001.02",
    "semt.006.001.02",
    "semt.007.001.02",
    "semt.013.001.04",
    "semt.014.001.06",
    "semt.015.001.07",
    "semt.016.001.07",
    "semt.017.001.09",
    "semt.018.001.10",
    "semt.019.001.08",
    "semt.020.001.05",
    "semt.021.001.06",
    "semt.022.001.04",
    "semt.023.001.01",
    "semt.024.001.01",
    "semt.041.001.02",
    "semt.042.001.01",
    "semt.998.001.03",
    "sese.001.001.08",
    "sese.002.001.08",
    "sese.003.001.08",
    "sese.004.001.08",
    "sese.005.001.08",
    "sese.006.001.08",
    "sese.007.001.08",
    "sese.008.001.08",
    "sese.009.001.06",
    "sese.010.001.06",
    "sese.011.001.07",
    "sese.012.001.09",
    "sese.013.001.09",
    "sese.014.001.09",
    "sese.018.001.07",
    "sese.019.001.06",
    "sese.020.001.06",
    "sese.021.001.05",
    "sese.022.001.05",
    "sese.023.001.09",
    "sese.024.001.10",
    "sese.025.001.09",
    "sese.026.001.08",
    "sese.027.001.05",
    "sese.028.001.08",
    "sese.029.001.04",
    "sese.030.001.08",
    "sese.031.001.08",
    "sese.032.001.09",
    "sese.033.001.09",
    "sese.034.001.08",
    "sese.035.001.09",
    "sese.036.001.07",
    "sese.037.001.05",
    "sese.038.001.07",
    "sese.039.001.04",
    "sese.040.001.02",
    "setr.001.001.04",
    "setr.002.001.04",
    "setr.003.001.04",
    "setr.004.001.04",
    "setr.005.001.04",
    "setr.006.001.04",
    "setr.007.001.04",
    "setr.008.001.04",
    "setr.009.001.04",
    "setr.010.001.04",
    "setr.011.001.04",
    "setr.012.001.04",
    "setr.013.001.04",
    "setr.014.001.04",
    "setr.015.001.04",
    "setr.016.001.04",
    "setr.017.001.04",
    "setr.018.001.04",
    "setr.027.001.03",
    "setr.029.001.01",
    "setr.030.001.01",
    "setr.044.001.02",
    "setr.047.001.02",
    "setr.048.001.01",
    "setr.049.001.02",
    "setr.050.001.01",
    "setr.051.001.01",
    "setr.051.001.02",
    "setr.052.001.01",
    "setr.053.001.02",
    "setr.054.001.01",
    "setr.055.001.02",
    "setr.056.001.01",
    "setr.057.001.02",
    "setr.058.001.02"
];

export const SEPA_MESSAGES_KEYS = [
    "camt.027.001.07",
    "camt.029.001.08",
    "camt.056.001.08",
    "camt.087.001.06",
    "pacs.002.001.10",
    "pacs.004.001.09",
    "pacs.008.001.08",
    "pacs.028.001.03",
    "pacs.002.001.10.dd",
    "pacs.003.001.08",
    "pacs.004.001.09.dd",
    "pacs.007.001.09"
];

export const CBPR_PLUS_MESSAGES_KEYS = [
    "camt.029.001.09",
    "camt.052.001.08",
    "camt.053.001.08",
    "camt.054.001.08",
    "camt.055.001.08",
    "camt.056.001.08",
    "camt.057.001.06",
    "camt.058.001.08",
    "camt.060.001.05",
    "camt.107.001.01",
    "camt.108.001.01",
    "camt.109.001.01",
    "pacs.002.001.10",
    "pacs.003.001.08",
    "pacs.004.001.09",
    "pacs.008.001.08",
    "pacs.008.001.08.stp",
    "pacs.009.001.08.adv",
    "pacs.009.001.08.core",
    "pacs.009.001.08.cov",
    "pacs.010.001.03",
    "pacs.010.001.03.col",
    "pain.001.001.09",
    "pain.002.001.10",
    "pain.008.001.08"
]


export const TRANSLATOR_CBPR_MT_MESSAGES_KEYS = [
    "103",
    "192",
    "196",
    "202",
    "202adv",
    "205",
    "205cov",
    "205return",
    "296",
    "900",
    "910",
    "103stp",
    "103return",
    "202cov",
    "202return"
];

export const TRANSLATOR_CBPR_MX_MESSAGES_KEYS = [
    "pacs.008.001.08",
    "pacs.008.001.08.stp",
    "pacs.009.001.08.core",
    "pacs.009.001.08.cov",
    "camt.029.001.09",
    "camt.056.001.08",
    "camt.054.001.08",
    "camt.053.001.08",
    "pacs.002.001.10",
    "pacs.004.001.09",
    "pacs.009.001.08.adv",
    "camt.052.001.08",
    "camt.057.001.06"
];

export const TARGET_TWO_MESSAGES_KEYS = [
    "admi.007.001.01",
    "camt.025.001.05",
    "camt.029.001.09",
    "camt.050.001.05",
    "camt.053.001.08",
    "camt.054.001.08",
    "camt.056.001.08",
    "pacs.002.001.10",
    "pacs.004.001.09",
    "pacs.008.001.08",
    "pacs.009.001.08",
    "pacs.010.001.03"
];

export const SIC_EUROSIC_MESSAGES_KEYS = [
    "camt.029.001.09",
    "camt.056.001.08",
    "pacs.002.001.10",
    "pacs.004.001.09",
    "pacs.008.001.08"
];

export const SCRIPS_MEPS_MESSAGES_KEYS = [
    "camt.029.001.09",
    "camt.053.001.08",
    "camt.053.001.08.aos",
    "camt.056.001.08",
    "pacs.008.001.08",
    "pacs.009.001.08",
    "pacs.009.001.08.cov",

];

export const TRANSLATOR_TARGET_TWO_MT_MESSAGES_KEYS = [
    "103",
    "202",
    "103return",
    "202cov",
    "202return"
];

export const TRANSLATOR_TARGET_TWO_MX_MESSAGES_KEYS = [
    "pacs.008.001.08",
    "pacs.009.001.08.core",
    "pacs.009.001.08.cov",
    "camt.054.001.08",
    "camt.053.001.08",
    "pacs.004.001.09"
];

export const FEDNOW_MESSAGES_KEYS = [
    "admi.002.001.01",
    "admi.007.001.01",
    "camt.029.001.09",
    "camt.052.001.08.account.activity.totals.report",
    "camt.052.001.08.account.activity.details.report",
    "camt.052.001.08.account.balance.report",
    "camt.054.001.08",
    "camt.056.001.08",
    "camt.060.001.05",
    "head.001.001.02",
    "pacs.002.001.10.fednow.payment.status",
    "pacs.002.001.10.participant.payment.status",
    "pacs.004.001.10",
    "pacs.008.001.08",
    "pacs.028.001.03",
    "pacs.009.001.08"
];

export const MSG_USR_PERM = {
    'unAuth': {
        mt: ['101'],
        mx: ['pain.001.001.09'],
        sepa: ['pacs.008.001.08'],
        translatorCbprMt: ['202'],
        translatorCbprMx: ['pacs.009.001.08.core'],
        cbprPlus: ['pacs.008.001.08', 'pacs.009.001.08.core', 'camt.056.001.08'],
        targetTwo: ['pacs.008.001.08', 'pacs.009.001.08', 'camt.056.001.08'],
        sicEuroSic: ['pacs.002.001.10'],
        scripsMeps: ['pacs.009.001.08'],
        translatorTargetTwoMt: ['202'],
        translatorTargetTwoMx: ['pacs.009.001.08.core'],
        fedNow: ['']

    },
    'freeActive': {
        mt: MT_MESSAGES_KEYS,
        mx: MX_MESSAGES_KEYS,
        sepa: SEPA_MESSAGES_KEYS,
        cbprPlus: CBPR_PLUS_MESSAGES_KEYS,
        translatorCbprMt: TRANSLATOR_CBPR_MT_MESSAGES_KEYS,
        translatorCbprMx: TRANSLATOR_CBPR_MX_MESSAGES_KEYS,
        targetTwo: TARGET_TWO_MESSAGES_KEYS,
        sicEuroSic: SIC_EUROSIC_MESSAGES_KEYS,
        scripsMeps: SCRIPS_MEPS_MESSAGES_KEYS,
        translatorTargetTwoMt: TRANSLATOR_TARGET_TWO_MT_MESSAGES_KEYS,
        translatorTargetTwoMx: TRANSLATOR_TARGET_TWO_MX_MESSAGES_KEYS,
        fedNow: FEDNOW_MESSAGES_KEYS
    },
    'unlimitedActive': {
        mt: MT_MESSAGES_KEYS,
        mx: MX_MESSAGES_KEYS,
        sepa: SEPA_MESSAGES_KEYS,
        cbprPlus: CBPR_PLUS_MESSAGES_KEYS,
        translatorCbprMt: TRANSLATOR_CBPR_MT_MESSAGES_KEYS,
        translatorCbprMx: TRANSLATOR_CBPR_MX_MESSAGES_KEYS,
        targetTwo: TARGET_TWO_MESSAGES_KEYS,
        sicEuroSic: SIC_EUROSIC_MESSAGES_KEYS,
        scripsMeps: SCRIPS_MEPS_MESSAGES_KEYS,
        translatorTargetTwoMt: TRANSLATOR_TARGET_TWO_MT_MESSAGES_KEYS,
        translatorTargetTwoMx: TRANSLATOR_TARGET_TWO_MX_MESSAGES_KEYS,
        fedNow: FEDNOW_MESSAGES_KEYS
    },
    'freeInactive': {
        mt: ['101'],
        mx: ['pain.001.001.09'],
        sepa: ['pacs.008.001.08'],
        translatorCbprMt: ['202'],
        translatorCbprMx: ['pacs.009.001.08.core'],
        cbprPlus: ['pacs.008.001.08', 'pacs.009.001.08.core', 'camt.056.001.08'],
        targetTwo: ['pacs.008.001.08', 'pacs.009.001.08', 'camt.056.001.08'],
        sicEuroSic: ['pacs.002.001.10'],
        scripsMeps: ['pacs.009.001.08'],
        translatorTargetTwoMt: ['202'],
        translatorTargetTwoMx: ['pacs.009.001.08.core'],
        fedNow: ['']

    },
    'unlimitedInactive': {
        mt: ['101'],
        mx: ['pain.001.001.09'],
        sepa: ['pacs.008.001.08'],
        translatorCbprMt: ['202'],
        translatorCbprMx: ['pacs.009.001.08.core'],
        cbprPlus: ['pacs.008.001.08', 'pacs.009.001.08.core', 'camt.056.001.08'],
        targetTwo: ['pacs.008.001.08', 'pacs.009.001.08', 'camt.056.001.08'],
        sicEuroSic: ['pacs.002.001.10'],
        scripsMeps: ['pacs.009.001.08'],
        translatorTargetTwoMt: ['202'],
        translatorTargetTwoMx: ['pacs.009.001.08.core'],
        fedNow: ['']
    }
}

export const defineGenericMsg = (permission) => {

    const permissions = MSG_USR_PERM[permission];
    const mtStr = permissions.mt.map((r)=> {
        return " MT"+ r;
    }).toString();
    const mxStr = permissions.mx.map((r)=> {
        return " " + r;
    }).toString();
    const sepaStr = permissions.sepa.map((r)=> {
        return " " + r;
    }).toString();
    const cbprPlusStr = permissions.cbprPlus.map((r)=> {
        return " " + r;
    }).toString();
    const translatorCbprMtStr = permissions.translatorCbprMt.map((r)=> {
        return " MT" + r;
    }).toString();
    const translatorCbprMxStr = permissions.translatorCbprMx.map((r)=> {
        return " " + r;
    }).toString();
    const targetTwoStr = permissions.targetTwo.map((r)=> {
        return " " + r;
    }).toString();
    const sicEuroSicStr = permissions.sicEuroSic.map((r)=> {
        return " " + r;
    }).toString();
    const scripsMepsStr = permissions.scripsMeps.map((r)=> {
        return " " + r;
    }).toString();
    const translatorTargetTwoMtStr = permissions.translatorTargetTwoMt.map((r)=> {
        return " MT" + r;
    }).toString();
    const translatorTargetTwoMxStr = permissions.translatorTargetTwoMx.map((r)=> {
        return " " + r;
    }).toString();
    const fedNowStr = permissions.fedNow.map((r)=> {
        return " " + r;
    }).toString();


    const str = 'SWIFT MT:' + mtStr + `\nISO20022:` + mxStr + `\nSEPA:` + sepaStr +
        `\nCBPR+:` + cbprPlusStr + `\nTARGET2:` + targetTwoStr + `\nSIC/euroSIC: ` + sicEuroSicStr +
        `\nSCRIPS (MEPS+): ` + scripsMepsStr +
        `\nTRANSLATE MT<>CBPR+:` + translatorCbprMtStr + ' <> ' + translatorCbprMxStr +
        `\nTRANSLATE MT<>TARGET2:` + translatorTargetTwoMtStr + ' <> ' + translatorTargetTwoMxStr +
        (fedNowStr.trim() ?`\nFEDNOW:` + fedNowStr : '' ) + '\n\n';

// const transStr = `TRANSLATE MT<>ISO20022: MT101 <> PAIN.001`;

    switch(permission) {
        case 'unAuth':
            return`/* READ THIS\n
Unregistered users have access to the following message samples:\n
${str}*/`;
        case 'freeActive':
            return` /* READ THIS\n
You have access for ${FREE_PLAN_DURATION} days to all the message samples.\n
You can edit the sample messages or paste your own, validate and translate until you spent your 20 actions. All functions are also available through API.\n
If you want unrestricted validations and translations, you can subscribe to unlimited plan or purchase the FINaplo Financial Messages SDKs.\n
Enjoy! */`;
        case 'unlimitedActive':
            return`/* READ THIS\n
You have unlimited access for ${UNLIMITED_PLAN_DURATION} days to all the message samples.\n
You can edit the samples or paste, validate and translate your own messages.\n
Enjoy! */`;
        case 'freeInactive':
            return`/* READ THIS\n
Your FINaplo Financial Services Free Trial has expired.\n
Purchase the FINaplo Online Financial Messages & API Service to have unlimited access for ${UNLIMITED_PLAN_DURATION} days.\n
Don't forget! You can have unlimited access to Online & API for a year if you purchase the FINaplo Financial Messages SDK. */`;
        case 'unlimitedInactive':
            return`/* READ THIS\n
Time flies! The period of unlimited usage has ended.\n
Purchase a new ${UNLIMITED_PLAN_DURATION} days unlimited plan or you can have unlimited access for a year if you purchase a FINaplo Financial Messages SDK. */`;
        default:
            return `Welcome to Financial Messages Checker, the best tool!`

    }
}

export function checkMessageType(msg, type, category, usrPerm) {
    let result;
    let modType;
    if (category === 'mt' || category === 'translatorCbprMt' || category === 'translatorTargetTwoMt') {
        modType =  type;
        if (type.includes('cov')) {
            modType = modType.replace('cov', '');
        }
        let no = msg.lastIndexOf('{2:O');
        let ni = msg.lastIndexOf('{2:I');
        let n = no > 0 ? no : ni;
        result = msg.substring(n + 4).substr(0, 3);
    }

    if (category === 'mx' || category === 'sepa' ||
        category === 'translatorCbprMx' ||  category === 'cbprPlus' ||
        category === 'targetTwo' || category === 'translatorTargetTwoMx' ||
        category === 'sicEuroSic' || category === 'scripsMeps' ||
        category === 'fedNow') {
        let regex = msg.match(new RegExp(":xsd:(?!head)(.*)\""));
        modType = type;
        result = regex && regex.length > 0 ? regex[1].replace(':xsd:', '') : msg;
    }
    return ((usrPerm === 'unlimitedActive' || usrPerm === 'freeActive') ? result: (result && (result.includes(modType) || modType.includes(result)) &&
        MSG_USR_PERM[usrPerm][category].includes(type)));
}
