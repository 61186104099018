import React, {} from "react";
import {ApiInstructions} from "./ApiInstructions";

const ApiInstructionsLazyHelper =(props)=>  {
    const {swagData} = props

    return (
      <ApiInstructions swagData={swagData} />
    );
};

export default ApiInstructionsLazyHelper;