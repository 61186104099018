import React from 'react';
import {FinMessagesPageContent} from './FinMessagesPageContent';

const FinMessagesLazyHelper =(props)=>  {
    return (
        <React.Fragment>
            <FinMessagesPageContent />
        </React.Fragment>
    );
};

export default FinMessagesLazyHelper;
