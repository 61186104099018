import React, {lazy, Suspense, useContext, useEffect, useState} from 'react';
import store from "../../../../../store";
import {Button} from 'react-bootstrap';
import {AppContext} from '../../../../../../App';
import {finMessageRequest} from '../../../../../providers/finMessageRequest';
import {ROUTE_PATHS, UNPARSEABLE_XML, VALIDATE_INSTRUCTIONS, MSG_HINT} from '../../../../../lib/constants';
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    checkEmptyStr, checkGenericServerError,
    checkRespData,
    compareTrialsState,
    getRespEls, getResultMessageType,
    sendGaEvent
} from "../../../../../lib/utils";
import AnimateOnChange from 'react-animate-on-change';
import {getUserProfile} from "../../../../../providers/userService";
import {checkMessageType, defineGenericMsg} from "../../../../../lib/msgUserPermissions";

const CodeMirror = lazy(() =>
    import("react-codemirror2").then(module => ({default: module.Controlled}))
);

const CbprPlusContentComponent = (props) => {
    const {selectedMsgSubType, changeRespObject, respObject, userPermission, history, location} = props;
    const [cbprPlusMessages, setCbprPlusMessages] = useState(null);
    const [msgValue, setMsgValue] = useState('');
    const [msgTitle, setMsgTitle] = useState('');
    const {state, dispatch} = useContext(AppContext);
    const [animate, setAnimate] = useState(false);
    const [disableCbprPlusBtn, setDisableCbprPlusBtn] = useState(false);
    const [disableCbprPlusEnvelopeBtn, setDisableCbprPlusEnvelopeBtn] = useState(false);
    const [disableCbprPlusReturnBtn, setDisableCbprPlusReturnBtn] = useState(false);
    const [disableCbprPlusRecallBtn, setDisableCbprPlusRecallBtn] = useState(false);
    const [disableCbprPlusRecallResponseBtn, setDisableCbprPlusRecallResponseBtn] = useState(false);
    const [showReturnRecallBtns, setShowReturnRecallBtns] = useState(false);
    const [showRecallResponseBtn, setShowRecallResponseBtn] = useState(false);
    const [showClearBtn, setShowClearBtn] = useState(false);
    const [respObj, setRespObj] = useState(respObject);
    const [visualRes, setVisualRes] = useState(null);
    const [msgIsComment, setMsgIsComment] = useState(false);
    const [textareaError, setTextAreaError] = useState(false);

    const user = useSelector(state => state.userReducer);
    const message = useSelector(state => state.messageReducer);
    const plans = useSelector(state => state.plansReducer);

    import ('../../../../../lib/finMessagesSamples/cbpr_plus_tree').then(tree => {
        setCbprPlusMessages(tree.CBPR_PLUS_MESSAGES);
    });

    useEffect(()=> {

        const logData = location && location.state && location.state.logData ? location.state.logData: null;
        
        if (selectedMsgSubType && cbprPlusMessages && cbprPlusMessages[selectedMsgSubType] && !logData) {
            setRespObj(null);
            setMsgValue(MSG_HINT + cbprPlusMessages[selectedMsgSubType].msg);
            setMsgTitle('CBPR+ ' + cbprPlusMessages[selectedMsgSubType].title);
            setMsgIsComment(false);
        }
        if (userPermission && !selectedMsgSubType && plans.payload && !logData) {
            let title = userPermission !== 'unAuth' && userPermission !== 'freeInactive' &&
            userPermission !== 'unlimitedInactive' ? VALIDATE_INSTRUCTIONS: '';
            setRespObj(null);
            setMsgTitle(title);
            setMsgIsComment(true);
            const genericMsg = defineGenericMsg(userPermission, plans.payload[0].limit);
            setMsgValue(prevMsgValue => prevMsgValue !== genericMsg ? genericMsg : prevMsgValue );
        }

    }, [cbprPlusMessages, selectedMsgSubType, userPermission, location, plans.payload]);

    useEffect(()=> {
        if (message.cbprPlusValidate) {
            setDisableCbprPlusBtn(message.cbprPlusValidate.fetching);
        }
    },[message.cbprPlusValidate]);

    useEffect(()=> {
        if (message.cbprPlusEnvelope) {
            setDisableCbprPlusEnvelopeBtn(message.cbprPlusEnvelope.fetching);
        }
    },[message.cbprPlusEnvelope]);

    useEffect(()=> {
        if (message.cbprPlusReturn) {
            setDisableCbprPlusReturnBtn(message.cbprPlusReturn.fetching);
        }
    },[message.cbprPlusReturn]);

    useEffect(()=> {
        if (message.cbprPlusRecall) {
            setDisableCbprPlusRecallBtn(message.cbprPlusRecall.fetching);
        }
    },[message.cbprPlusRecall]);

    useEffect(()=> {
        if (message.cbprPlusRecallResponse) {
            setDisableCbprPlusRecallResponseBtn(message.cbprPlusRecallResponse.fetching);
        }
    },[message.cbprPlusRecallResponse]);

    useEffect(()=> {
        setRespObj(respObject)
    },[respObject]);

    useEffect(()=> {
        if (respObj) {
            setVisualRes(getRespEls(respObj));
        }
    },[respObj]);

    useEffect(()=> {
        changeRespObject(null);
        if (user && user.userPermission) {
            setShowClearBtn(user.userPermission !== 'unAuth' && !user.userPermission.endsWith('Inactive'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.userPermission]);

    useEffect(()=> {
        if (msgTitle) {
            setShowReturnRecallBtns(typeof msgTitle == 'string' && (msgTitle.includes('pacs.008.001.08') || (msgTitle.includes('pacs.009.001.08') && !msgTitle.includes('ADV'))))
            setShowRecallResponseBtn(typeof msgTitle == 'string' && (msgTitle.includes('camt.056.001.08')))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[msgTitle]);

    useEffect(() => {
        let err = location && location.state && location.state.from === "validationError";
        setTextAreaError(err);
        if (err) {
            setTimeout(() => {
                setTextAreaError(false);
            }, 900);
        }

        const logData = location && location.state && location.state.logData ? location.state.logData: null;
        if (logData) {
            const isError = logData.isValid !== 'valid';
            const respObj = {validity: logData.isValid,
                content: isError ? logData.errorResp : logData.message,
                reqType: 'validate',
                hideRespArea: !isError,
                msgType: 'cbprPlus'
            };
            changeRespObject(respObj)
            setMsgTitle(logData.title);
            setMsgIsComment(false);
            setMsgValue(logData.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location]);
    
    const handleChange  = code => {
        setMsgValue(code);
        setRespObj(null);
    };

    const toggleAnimation = () => {
        setAnimate(true);
        setTimeout(() => {
            setAnimate(false);
        }, 100);
    };

    const sendRequest = (path, reqTypeParam, hideRespArea, recallRespRsnInf) => {
       let validPermMsg = checkMessageType(msgValue, selectedMsgSubType, 'cbprPlus', userPermission);
       if (!validPermMsg) {
           history.push({
               pathname: '/' + ROUTE_PATHS['checker'] + '/cbpr/plus',
               state: {from: "validationError"}
           });
       } else {
           store.dispatch(finMessageRequest(path, msgValue.replace(MSG_HINT, ''), false, reqTypeParam, null, null, recallRespRsnInf)).then((response) => {
               toggleAnimation();
               let resp = response.value;
               let trials = resp.headers['x-remaining-trials'];
               if (compareTrialsState(trials, state.remainingTrials)) {
                   dispatch({...state, remainingTrials: trials, animateTrials: true})
               }
               if (resp.status === 200) {
                   let respType = getResultMessageType(resp.data, 'cbprPlusEnvelope');
                   let respObject = {
                       validity: 'valid',
                       content: resp.data,
                       reqType: reqTypeParam,
                       respType: respType,
                       hideRespArea: hideRespArea
                   };
                   changeRespObject(respObject);
               }
           }, (error) => {
               toggleAnimation();
               let resp = error.response;
               let trials = resp.headers['x-remaining-trials'];
               if (compareTrialsState(trials, state.remainingTrials)) {
                   dispatch({...state, remainingTrials: trials, animateTrials: true})
               }
               if (resp.status === 400 || resp.status === 500) {
                   let respObject = {
                       validity: checkEmptyStr(resp.data.message) && checkGenericServerError(resp.data) ? 'invalid__server-error' : 'invalid',
                       content: checkRespData(resp.data),
                       reqType: reqTypeParam,
                       msgType: 'cbprPlus'
                   };
                   changeRespObject(respObject);
               } else if (resp.status=== 412 || resp.status === 429) {
                   history.push('/' + ROUTE_PATHS['checker'] + '/cbpr/plus');
                   dispatch({...state,
                       noChecksFlag: true,
                       remainingTrials: 0
                   });
               } else if (resp.status === 401) {
                   store.dispatch(getUserProfile("/me")).then(()=> {
                   }, ()=> {
                       history.push({
                           pathname: '/login',
                           state: {from: ROUTE_PATHS['checker'] + '/cbpr/plus'}
                       });
                   });
               } else {
                   let respObject = {validity: 'error', content: [UNPARSEABLE_XML], reqType: reqTypeParam};
                   changeRespObject(respObject);
               }
           });
       }
    };

    const getElCls = (msg) => {
        return (showReturnRecallBtns || showRecallResponseBtn) ? 'msg-content__bottom--hide-lbl': '';
    }

    return (
         <div className="msg-content msg-content--cbpr-plus">
             <p className={!msgIsComment ? 'msg-content__title': ''}>{msgTitle}</p>
             {respObj && visualRes ?
                 <div className={visualRes.bg}>
                     <AnimateOnChange
                         baseClassName='msg-validity_content'
                         animationClassName='msg-validity-animation'
                         animate={animate}>
                         <span className='msg-validity_text'>
                            {visualRes.text}
                         </span>
                         <div className='msg-validity_icon-wrapper'>
                             <span className={visualRes.img}>
                             </span>
                         </div>
                     </AnimateOnChange>
                 </div>
              :null
             }
             {userPermission === 'unAuth' || userPermission === 'freeInactive' ||
             userPermission === 'unlimitedInactive' || msgIsComment ?
                 <Suspense fallback={<div style={{height:"384px",backgroundColor:"#1E1E1E"}}/>}>
                     <CodeMirror
                         className={'xml-codemirror custom-codemirror unselectable' +
                         (msgIsComment ? ' comment-txt': '') +
                         (textareaError ? ' invalid-txt': '')}
                         value={msgValue}
                         options={{
                             mode: 'xml',
                             theme: 'checker-code',
                             lineNumbers: true,
                             lineWrapping: true,
                             matchBrackets: false,
                             readOnly: 'nocursor',
                             cursorHeight: 0,
                             autoRefresh: true,
                             firstLineNumber: msgValue.includes(MSG_HINT) ? 0 : 1
                         }}
                         onBeforeChange={(editor, data, value) => {
                             return null;
                         }}
                     />
                 </Suspense>:
                 <Suspense fallback={<div style={{height:"384px",backgroundColor:"#1E1E1E"}}/>}>
                     <CodeMirror
                         className={"xml-codemirror custom-codemirror" +
                         (msgIsComment ? ' comment-txt': '') +
                         (textareaError ? ' invalid-txt': '')}
                         value={msgValue}
                         options={{
                             mode: 'xml',
                             theme: 'checker-code',
                             lineNumbers: true,
                             lineWrapping: true,
                             matchTags: {bothTags: true},
                             readOnly: false,
                             cursorHeight: 0.8,
                             autoRefresh: true,
                             firstLineNumber: msgValue.includes(MSG_HINT) ? 0 : 1
                         }}
                         onBeforeChange={(editor, data, value) => {
                             handleChange(value)
                         }}
                     />
                 </Suspense>
             }
             <div className='msg-content__bottom'>
                 {userPermission && selectedMsgSubType ?
                     <React.Fragment>
                         { showClearBtn ?
                             <Button variant="info"
                                     className={"responsive-label" +
                                     (showReturnRecallBtns ? ' clear-btn-min-width-a': '') +
                                     (showRecallResponseBtn ? ' clear-btn-min-width-b': '')}
                                     title='Clear Message'
                                     disabled={!msgValue}
                                     onClick={()=> {
                                        setMsgValue('');
                                     }}>
                                    <span>
                                        {'Clear '}
                                    </span>
                                    {!showReturnRecallBtns ?
                                        <span className={getElCls(msgTitle)}>
                                            {'Message'}
                                        </span>
                                    :null}
                             </Button>
                         : null}
                         <div className={"msg-content__bottom--buttons" + (!showClearBtn ? " full-width": "")}>
                             <Button variant="info"
                                title='Validate Message'
                                disabled={disableCbprPlusBtn}
                                onClick={()=> {
                                    sendRequest('/cbpr/validate', 'validate', true);
                                    sendGaEvent({category: 'demo', action: 'validate-cbpr-plus'});
                                }}>
                                <span>
                                    {'Validate '}
                                </span>
                                <span className={getElCls(msgTitle)}>
                                    {'Message'}
                                </span>
                             </Button>
                             <Button variant="info"
                                     title='Envelope Message'
                                     disabled={disableCbprPlusEnvelopeBtn}
                                     onClick={()=> {
                                         sendRequest('/cbpr/envelope', 'cbprEnvelope');
                                         sendGaEvent({category: 'demo', action: 'envelope-cbpr-plus'});
                                     }}>
                                <span>
                                    {'Envelope '}
                                </span>
                                 <span className={getElCls(msgTitle)}>
                                    {'Message'}
                                </span>
                             </Button>
                             {showReturnRecallBtns ?
                                 <React.Fragment>
                                     <Button variant="info" className="responsive-label"
                                             title='Generate: Payment Return'
                                             disabled={disableCbprPlusReturnBtn}
                                             onClick={()=> {
                                                 sendRequest('/cbpr/return', 'return_payment', false, 'AC01');
                                                 sendGaEvent({category: 'demo', action: 'returnPayment-cbpr'});
                                             }}>
                                        <span className={getElCls(msgTitle)}>
                                            {'Generate: Payment '}
                                        </span>
                                         <span>
                                            {'Return'}
                                        </span>
                                     </Button>
                                     <Button variant="info" className="responsive-label"
                                             title='Generate: Request for Recall'
                                             disabled={disableCbprPlusRecallBtn}
                                             onClick={()=> {
                                                 sendRequest('/cbpr/recall/request', 'request_recall', false, 'CUST');
                                                 sendGaEvent({category: 'demo', action: 'requestRecall-cbpr'});
                                             }}>
                                         <span className={getElCls(msgTitle)}>
                                            {'Generate: Request for '}
                                        </span>
                                         <span>
                                            {'Recall'}
                                        </span>
                                     </Button>
                                 </React.Fragment>: showRecallResponseBtn ?
                                 <Button variant="info" className="responsive-label"
                                         title='Generate: Recall Response'
                                         disabled={disableCbprPlusRecallResponseBtn}
                                         onClick={()=> {
                                             sendRequest('/cbpr/recall/response', 'recall_response', false, "NOAS");
                                             sendGaEvent({category: 'demo', action: 'recallResponse-cbpr'});
                                         }}>
                                        <span className={getElCls(msgTitle)}>
                                            {'Generate: '}
                                        </span>
                                         <span>
                                            {'Recall Response'}
                                        </span>
                                 </Button>
                             :null}
                         </div>
                     </React.Fragment>
                    :null}
             </div>
        </div>
    );
};

export const CbprPlusMsgContent = withRouter(CbprPlusContentComponent);
