import React, {useEffect, useState} from 'react';
import store from "../../../../store";
import {sendEmailComment} from "../../../../providers/emailService";
import moment from "moment";


function EmailComment() {
    const [msg, setMsg] = useState("");
    const [msgState, setMsgState] = useState('not-sent');
    const [dailyMsg, setDailyMsg] = useState("Tell us, what's missing?");

    let currDay = moment(new Date()).format('dddd');

    useEffect(()=> {
        setDailyMsg(getDailyMessage(currDay));
    },[currDay]);

    const handleChange = (e) => {
        let val = e.target.value;
        let valValid= !(val.length === 0 || !val.trim()) ? val : '';
        setMsg(valValid);
    };

    const handleSend = () => {
        store.dispatch(sendEmailComment('/email', msg)).then((response)=> {
            setMsg("");
            setMsgState("success-sent");
        }, (error) => {
            setMsg("");
            setMsgState("fail-sent");
        });
    }

    const handleFocus = () => {
        setMsgState("");
    }

    const handleBlur = () => {
        setMsgState("not-sent");
    }

    const getDailyMessage = (day) => {
        switch (day) {
            case 'Monday' :
                return 'Anything missing from the Checker?';
            case 'Tuesday' :
                return 'What can we do to improve this tool?';
            case 'Wednesday' :
                return 'Got suggestions? Share them with us!';
            case 'Thursday':
                return 'Anything you don’t like in the Checker?';
            case 'Friday':
                return 'How can we help you?';
            case 'Saturday':
                return 'What feature would you add to Checker?';
            case 'Sunday':
                return 'Request an improvement';
            default:
                return "Tell us, what's missing?";
        }
    }

    const arrangePlaceholderTxt = () => {
        switch(msgState) {
            case "not-sent":
                return dailyMsg;
            case "success-sent":
                return "Message Sent";
            case "fail-sent":
                return "Something's wrong. Try again later"
            case "":
                return "";
            default:
                return dailyMsg;
        }
    }


    const arrangeButtonTxt = () => {
        switch(msgState) {
            case "not-sent":
                return "Send";
            case "success-sent":
                return "Thanks";
            case "fail-sent":
                return ":("
            default:
                return "Send";
        }
    }

    return (

        <div className="email-comment">
            <input type="text" maxLength="200"
                   onChange={handleChange}
                   onFocus={handleFocus}
                   onBlur={handleBlur}
                   placeholder={arrangePlaceholderTxt()}
                   className={msgState === "success-sent" ? "success": (msgState === "fail-sent" ? "fail": "")}
                   value={msg}
            />
            <button disabled={!msg}
                    className={(msgState === "success-sent" ? "after-send" :
                        (msgState === "fail-sent" ? "after-send fail": "before-send"))}
                    onClick={handleSend}>
                {arrangeButtonTxt()}</button>
        </div>
    );
}

export default EmailComment;
