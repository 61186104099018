import React from 'react';


const QuestionMailTo =(props)=> {
    const {subject} = props;
    return (
        <article className='question'>
            <a className="action__button--question question__btn"
               href={"mailto:finaplo@paymentcomponents.com?subject=" + subject + "&body="}>
                Have a question?
            </a>
        </article>
    );
}

export default QuestionMailTo;
