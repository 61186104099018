import {makeRequest} from "../api/api-client";

export const getMessagesTypeCountChartData= () => {
    let path = '/log/chart/messages/type/count';

    if (process.env.NODE_ENV === "production" ){
        path = process.env.SERVER_URL + path;
    }

    return makeRequest(path, "GET", null, null, "GET_MESSAGES_TYPE_COUNT");
};

export const getMessagesTypeCurrencyChartData = () => {
    let path = '/log/chart/messages/type/currency';

    if (process.env.NODE_ENV === "production" ){
        path = process.env.SERVER_URL + path;
    }

    return makeRequest(path, "GET", null, null, "GET_MESSAGES_TYPE_CURRENCY");
};
