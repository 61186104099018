import React, {useEffect, useState, useRef} from 'react';
import { withRouter } from 'react-router-dom';
import Select, {components} from 'react-select';
import {useSelector} from "react-redux";
import {MSG_USR_PERM} from "../../../../lib/msgUserPermissions";
import {ROUTE_PATHS} from "../../../../lib/constants";

const SearchBoxComponent = (props) =>  {
    const {history, location} = props;
    const [mtMessages, setMtMessages] = useState(null);
    const [mxMessages, setMxMessages] = useState(null);
    const [sepaMessages, setSepaMessages] = useState(null);
    const [cbprPlusMessages, setCbprPlusMessages] = useState(null);
    const [translatorCbprMtMessages, setTranslatorCbprMtMessages] = useState(null);
    const [translatorCbprMxMessages, setTranslatorCbprMxMessages] = useState(null);
    const [targetTwoMessages, setTargetTwoMessages] = useState(null);
    const [sicEuroSicMessages, setSicEuroSicMessages] = useState(null);
    const [scripsMepsMessages, setScripsMepsMessages] = useState(null);
    const [translatorTargetTwoMtMessages, setTranslatorTargetTwoMtMessages] = useState(null);
    const [translatorTargetTwoMxMessages, setTranslatorTargetTwoMxMessages] = useState(null);
    const [fedNowMessages, setFedNowMessages] = useState(null);
    const [value, setValue] = useState(null);
    const [options, setOptions] = (useState([]));
    const boxRef = useRef(null);
    const user = useSelector(state => state.userReducer);

    import ('../../../../lib/finMessagesSamples/mt_tree').then(tree => {
        setMtMessages(tree.MT_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/mx_tree').then(tree => {
        setMxMessages(tree.MX_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/sepa_tree').then(tree => {
        setSepaMessages(tree.SEPA_EPC_CT_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/cbpr_plus_tree').then(tree => {
        setCbprPlusMessages(tree.CBPR_PLUS_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/translator_cbpr_mt_tree').then(tree => {
        setTranslatorCbprMtMessages(tree.TRANSLATOR_CBPR_MT_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/translator_cbpr_mx_tree').then(tree => {
        setTranslatorCbprMxMessages(tree.TRANSLATOR_CBPR_MX_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/target_two_tree').then(tree => {
        setTargetTwoMessages(tree.TARGET_TWO_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/sic_eurosic_tree').then(tree => {
        setSicEuroSicMessages(tree.SIC_EUROSIC_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/scrips_meps_tree').then(tree => {
        setScripsMepsMessages(tree.SCRIPS_MEPS_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/translator_target_two_mt_tree').then(tree => {
        setTranslatorTargetTwoMtMessages(tree.TRANSLATOR_TARGET_TWO_MT_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/translator_target_two_mx_tree').then(tree => {
        setTranslatorTargetTwoMxMessages(tree.TRANSLATOR_TARGET_TWO_MX_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/fednow_tree').then(tree => {
        setFedNowMessages(tree.FEDNOW_MESSAGES);
    });

    const customStyles = {
        indicatorSeparator: null
    }

    const DropdownIndicator = (props) => {
        return components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
             <span className='bg-sprites bg-magnifying_glass' />
            </components.DropdownIndicator>
        );
    };

    useEffect(() =>{
        if (user.userPermission && mtMessages && mxMessages &&
            sepaMessages && cbprPlusMessages && fedNowMessages &&
            translatorCbprMtMessages && translatorCbprMxMessages &&
            targetTwoMessages && sicEuroSicMessages && scripsMepsMessages &&
            translatorTargetTwoMtMessages && translatorTargetTwoMxMessages) {
            const mtArr = Object.keys(mtMessages).reduce((mtRes, mt) => {
                if (MSG_USR_PERM[user.userPermission].mt.includes(mt)) {
                    mtRes.push({value: '/' + ROUTE_PATHS['checker'] + '/mt/' + mt, label: '(MT) ' + mtMessages[mt].title});
                }
                return mtRes;
            }, []);

            const mxArr = Object.keys(mxMessages).reduce((mxRes, mx) => {
                if (MSG_USR_PERM[user.userPermission].mx.includes(mx)) {
                    mxRes.push({value: '/' + ROUTE_PATHS['checker'] + '/mx/' + mx, label: '(ISO20022) ' + mxMessages[mx].title});
                }
                return mxRes;
            }, []);

            const sepaArr = Object.keys(sepaMessages).reduce((sepaRes, sepa) => {
                if (MSG_USR_PERM[user.userPermission].sepa.includes(sepa)) {
                    sepaRes.push({value: '/' + ROUTE_PATHS['checker'] + '/sepa/' + sepa, label: '(SEPA) ' + sepaMessages[sepa].title});
                }
                return sepaRes;
            }, []);

            const cbprPlusArr = Object.keys(cbprPlusMessages).reduce((cbprPlusRes, cbprPlus) => {
                if (MSG_USR_PERM[user.userPermission].cbprPlus.includes(cbprPlus)) {
                    cbprPlusRes.push({value: '/' + ROUTE_PATHS['checker'] + '/cbpr/plus/' + cbprPlus, label: '(CBPR+) ' + cbprPlusMessages[cbprPlus].title});
                }
                return cbprPlusRes;
            }, []);

            const transCbprMtArr = Object.keys(translatorCbprMtMessages).reduce((transCbprMtRes, transCbprMt) => {
                if (MSG_USR_PERM[user.userPermission].translatorCbprMt.includes(transCbprMt)) {
                    transCbprMtRes.push({value: '/' + ROUTE_PATHS['checker'] + '/translator/cbpr/mt/' + transCbprMt, label: '(TRANSLATE CBPR+) ' + translatorCbprMtMessages[transCbprMt].title});
                }
                return transCbprMtRes;
            }, []);

            const transCbprMxArr = Object.keys(translatorCbprMxMessages).reduce((transCbprMxRes, transCbprMx) => {
                if (MSG_USR_PERM[user.userPermission].translatorCbprMx.includes(transCbprMx)) {
                    transCbprMxRes.push({value: '/' + ROUTE_PATHS['checker'] + '/translator/cbpr/mx/' + transCbprMx, label: '(TRANSLATE CBPR+) ' + translatorCbprMxMessages[transCbprMx].title});
                }
                return transCbprMxRes;
            }, []);


            const targetTwoArr = Object.keys(targetTwoMessages).reduce((targetTwoRes, targetTwo) => {
                if (MSG_USR_PERM[user.userPermission].targetTwo.includes(targetTwo)) {
                    targetTwoRes.push({value: '/' + ROUTE_PATHS['checker'] + '/target2/' + targetTwo, label: '(TARGET2) ' + targetTwoMessages[targetTwo].title});
                }
                return targetTwoRes;
            }, []);

            const sicEuroSicArr = Object.keys(sicEuroSicMessages).reduce((sicEuroSicRes, sicEuroSic) => {
                if (MSG_USR_PERM[user.userPermission].sicEuroSic.includes(sicEuroSic)) {
                    sicEuroSicRes.push({value: '/' + ROUTE_PATHS['checker'] + '/sic/euroSic/' + sicEuroSic, label: '(SIC) ' + sicEuroSicMessages[sicEuroSic].title});
                }
                return sicEuroSicRes;
            }, []);

            const scripsMepsArr = Object.keys(scripsMepsMessages).reduce((scripsMepsRes, scripsMeps) => {
                if (MSG_USR_PERM[user.userPermission].scripsMeps.includes(scripsMeps)) {
                    scripsMepsRes.push({value: '/' + ROUTE_PATHS['checker'] + '/scrips/meps/' + scripsMeps, label: '(MEPS+) ' + scripsMepsMessages[scripsMeps].title});
                }
                return scripsMepsRes;
            }, []);

            const transTargetTwoMtArr = Object.keys(translatorTargetTwoMtMessages).reduce((transTargetTwoMtRes, transTargetTwoMt) => {
                if (MSG_USR_PERM[user.userPermission].translatorTargetTwoMt.includes(transTargetTwoMt)) {
                    transTargetTwoMtRes.push({value: '/' + ROUTE_PATHS['checker'] + '/translator/target2/mt/' + transTargetTwoMt, label: '(TRANSLATE TARGET2) ' + translatorTargetTwoMtMessages[transTargetTwoMt].title});
                }
                return transTargetTwoMtRes;
            }, []);

            const transTargetTwoMxArr = Object.keys(translatorTargetTwoMxMessages).reduce((transTargetTwoMtRes, transTargetTwoMx) => {
                if (MSG_USR_PERM[user.userPermission].translatorTargetTwoMx.includes(transTargetTwoMx)) {
                    transTargetTwoMtRes.push({value: '/' + ROUTE_PATHS['checker'] + '/translator/target2/mx/' + transTargetTwoMx, label: '(TRANSLATE TARGET2) ' + translatorTargetTwoMxMessages[transTargetTwoMx].title});
                }
                return transTargetTwoMtRes;
            }, []);

            const fedNowArr = Object.keys(fedNowMessages).reduce((fedNowRes, fedNow) => {
                if (MSG_USR_PERM[user.userPermission].fedNow.includes(fedNow)) {
                    fedNowRes.push({value: '/' + ROUTE_PATHS['checker'] + '/fedNow/' + fedNow, label: '(MEPS+) ' + fedNowMessages[fedNow].title});
                }
                return fedNowRes;
            }, []);

            const opts = mtArr.concat(mxArr, sepaArr,
                cbprPlusArr, transCbprMtArr, transCbprMxArr,
                targetTwoArr, sicEuroSicArr, scripsMepsArr,
                transTargetTwoMtArr, transTargetTwoMxArr, fedNowArr);
            setOptions(opts);
        }
    },[user.userPermission, mtMessages, mxMessages,
            sepaMessages, cbprPlusMessages,
            translatorCbprMtMessages,
            translatorCbprMxMessages,
            targetTwoMessages,
            sicEuroSicMessages,
            scripsMepsMessages,
            translatorTargetTwoMtMessages,
            translatorTargetTwoMxMessages,
            fedNowMessages]);

    useEffect(() =>{
        if (location.pathname !== '/' ||
            location.pathname.includes('/mt') ||
            location.pathname.includes('/mx') ||
            location.pathname.includes('/sepa') ||
            location.pathname.includes('/cbpr/plus/') ||
            location.pathname.includes('/translator/cbpr') ||
            location.pathname.includes('/target2') ||
            location.pathname.includes('/sic/euroSic') ||
            location.pathname.includes('/scrips/meps') ||
            location.pathname.includes('/translator/target2') ||
            location.pathname.includes('/fedNow')) {
            setValue('');
        }
    },[location.pathname]);


    const handleChange = (selection) => {
        history.push({
            pathname: selection.value,
        });
    }

    const handleFocus = () => {
        setValue(null);
    }

    return (
        <div className='search-msg'>
            <Select
                value = {
                    value
                }
                ref={boxRef}
                blurInputOnSelect={true}
                options={options}
                components={{DropdownIndicator}}
                styles={customStyles}
                onChange={handleChange}
                // defaultMenuIsOpen={true}
                // inputValue={inputValue}
                isSearchable={true}
                placeholder='Search all messages'
                isMulti={false}
                classNamePrefix='search-msg'
                className='search-msg__box'
                onFocus={handleFocus}
                onInputChange={inputValue =>
                    (inputValue.length <= 30 ? inputValue : inputValue.substr(0, 30))
                }
            />
        </div>
    );
};

export const SearchBox = withRouter(SearchBoxComponent);
