import React, {useState, useEffect} from 'react';
import {Link, withRouter} from "react-router-dom";
import store from "../../../../../store";
import {useSelector} from 'react-redux'
import {registerUser, resendConfirmationEmail} from '../../../../../providers/authService';
import {useForm} from "react-hook-form";
import {clearRegistrationState, setEnteredEmail} from "../../../../../actions/actions";
import InfoTooltip from "../../../../uiElements/InfoTooltip";
import {GA_EVENTS, pwPopoverMsg, ROUTE_PATHS} from "../../../../../lib/constants";
import {LoginLinkedin} from "../login/LoginLinkedin";
import AcceptTerms from "../AcceptTerms";
import {sendGaEvent} from "../../../../../lib/utils";


const RegisterPageContentComponent = (props) => {
    const {location, history} = props;
    const {handleSubmit, register, watch, errors} = useForm();
    const [termsChecked, setTermsChecked] = useState(false);
    const [containerClsName, setContainerClsName] = useState(null);
    const [emailError, setEmailError] = useState({show: false, msg: null});
    const [pwError, setPwError] = useState({show: false, msg: null});
    const [showEmailError, setShowEmailError] = useState(true);
    const [showPwError, setShowPwError] = useState(true);
    const [showConfPwError, setShowConfPwError] = useState(true);
    const [genericError, setGenericError] = useState({show: false, msg: null});
    const [precedentPath, setPrecedentPath] = useState(null);

    const user = useSelector(state => state.userReducer);

    useEffect(() => {
        let clsName = "auth__register" + (user.register.fetched ? " auth__register fetched": " not-fetched");
        setContainerClsName(clsName);
        setTermsChecked(user.register.fetched);
    },[user.register.fetched]);

    useEffect(() =>{
        if (user.userLoggedIn) {
            if (precedentPath) {
                history.push(precedentPath);
            } else {
                history.push('/' + ROUTE_PATHS['checker']);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.userLoggedIn]);

    useEffect(() => {
        if (location && location.state && location.state.from) {
            setPrecedentPath(location.state.from);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location]);


    const onSubmit = values => {
        delete values.passwordConfirmation;
        store.dispatch(registerUser('/register', values)).then((response)=> {
            store.dispatch(setEnteredEmail(values.email));
        }, (error) => {
            if (error.response && error.response.status === 400) {
                setEmailError({show: true, msg: "You cannot create a new account using this email address"});
                setShowEmailError(true);
            } else if (error.response && error.response.status === 412){
                setPwError({show: true, msg: "Not enough strong password"});
                setShowPwError(true);
            } else {
                setGenericError({show: true, msg: "You cannot create a new account using this email address"});
                setShowEmailError(true);
            }
        });
    };

    const handleCheck  = (e) => {
        setTermsChecked(e.target.checked);
    };

    const handleChange = (e) => {
        switch(e.target.name) {
            case "email":
                setShowEmailError(false);
                break;
            case "password":
                setShowPwError(false);
                break;
            case "passwordConfirmation":
                setShowConfPwError(false);
                break;
            default:
                return false;
        }
        setEmailError({show: false, msg: null});
        setPwError({show: false, msg: null});
        setGenericError({show: false, msg: null});
    };

    const fetchVerificationEmail = () => {
        store.dispatch(resendConfirmationEmail('/confirmation/resend', user.enteredEmail))
    };

    const clearStorageAndGotoRegistration = () => {
        store.dispatch(clearRegistrationState()).then(()=> {
            props.history.push('/register');
        })
    };

    return (
        <div className="auth__container">
            <div className={containerClsName}>
                {!user.register.fetched ?
                    <React.Fragment>
                        <div className="auth__register--content">
                            <Link to={precedentPath ? precedentPath : '/'}
                                  className="btn__close">×</Link>
                            <article>
                                <div className="auth__register--top">
                                    <h3>
                                        Sign up now <br/>
                                        to our Free Trial
                                    </h3>
                                    <h4>
                                        No credit card required!
                                    </h4>
                                </div>
                                <div className="auth__register--bottom">
                                    <div className="bg-auth bg-user_register"></div>
                                </div>
                            </article>
                            <form onSubmit={handleSubmit(onSubmit)} className="auth__form">
                                <h5>WHY DO WE NEED YOUR EMAIL?</h5>
                                <p className='step-1__paragraph'>
                                    {`You will receive a verification email. Without verification\nyou won’t be able to use our service. We will not spam you`}
                                </p>
                                <input
                                    name="email"
                                    type="text"
                                    placeholder="Your professional email *"
                                    autoComplete="on"
                                    onChange={handleChange}
                                    className={(errors.email || emailError.show || genericError.show) && showEmailError ? "auth__form--error": "" }
                                    ref={register({
                                        required: "Required field",
                                        pattern: {
                                            value: /^(([^<>()[+\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "Invalid email address"
                                        }
                                    })}
                                />
                                {errors.email && showEmailError ?
                                    <span className="error-msg-txt">
                                <i>
                                    {errors.email.message}
                                </i>
                            </span>:null}
                                <div className="auth__pw--tltp">
                                    <input
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        autoComplete="on"
                                        onChange={handleChange}
                                        className={(errors.password || pwError.show) && showPwError ? "auth__form--error": "" }
                                        ref={register({
                                            required: "Required field",
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,20})/,
                                                message: "Follow password rules"
                                            }
                                        })}
                                    />
                                    <InfoTooltip id='password-popover-register'
                                                 icon='bg-info_img'
                                                 placement='bottom'
                                                 content={pwPopoverMsg()}/>
                                </div>
                                {errors.password && showPwError ?
                                    <span className="error-msg-txt">
                                <i>
                                    {errors.password.message}
                                </i>
                            </span>:null}
                                <input
                                    name="passwordConfirmation"
                                    type="password"
                                    placeholder="Retype your password"
                                    autoComplete="on"
                                    onChange={handleChange}
                                    className={errors.passwordConfirmation && showConfPwError ? "auth__form--error": "" }
                                    ref={register({
                                        required: "Required field",
                                        validate: (value) => {
                                            return value === watch("password") || "The passwords do not match"
                                        }
                                    })}
                                />
                                {errors.passwordConfirmation && showConfPwError ?
                                    <span className="error-msg-txt">
                                <i>
                                    {errors.passwordConfirmation.message}
                                </i>
                            </span>:null}
                                <AcceptTerms termsChecked={termsChecked} handleCheck={handleCheck} type={'register'} />
                                <button className="auth__submit--btn" type="submit"
                                        disabled={!termsChecked}
                                        onClick={()=> {
                                            sendGaEvent({category: 'auth_action', action: GA_EVENTS.auth.register.native})
                                            setShowEmailError(true);
                                            setShowPwError(true);
                                            setShowConfPwError(true);
                                        }}
                                >SIGN UP</button>
                                {emailError.show ?
                                    <div className="auth__register--invalid-msg">
                                        <span> {emailError.msg}</span>
                                    </div>:null}
                                {pwError.show ?
                                    <div className="auth__register--invalid-msg">
                                        <span>{pwError.msg}</span>
                                    </div>:null}
                                {genericError.show ?
                                    <div className="auth__register--invalid-msg">
                                        <span>{genericError.msg}</span>
                                    </div>:null}
                                <LoginLinkedin termsChecked={termsChecked} gaEvent='register' />
                            </form>
                        </div>
                    </React.Fragment>:
                    <React.Fragment>
                        <div className="auth__register--content">
                            <article>
                                <div className="auth__register--verify__left">
                                    <h3>
                                        {`To continue\nyou have to verify\nyour email address!`}
                                    </h3>
                                    <p className='auth_step-2--paragraph'>
                                        {`Please check for the email message\nwe’ve sent and click on the link to\nverify your email`}
                                    </p>
                                </div>
                                <div className="auth__register--verify__right">
                                    <div className="bg-auth bg-shadow auth__action--fetched__shadow"></div>
                                    <div className="bg-auth bg-laptop auth__action--fetched__laptop"></div>
                                </div>

                            </article>
                        </div>
                        <div className="auth__register--email-verification">
                            <div className="auth__register--email-sent-to">
                                A VERIFICATION EMAIL WAS SENT TO
                            </div>
                            <div className="auth__register--entered-email">
                                {user.enteredEmail}
                            </div>
                            <p className="auth__register--resend-verification">
                            <span>
                                {`Check your INBOX and SPAM folder and allow 2-3 minutes to pass. In case you still have not received anything, `}
                                <button className="auth__reset--btn auth__reset--btn__resend"
                                        onClick={fetchVerificationEmail}>
                                            click here
                            </button>&nbsp;to request the resent of the email message
                            </span><br/>
                                <span>
                                {`In case you submitted a wrong email, `}
                                    <button className="auth__reset--btn auth__reset--btn__resend"
                                            onClick={clearStorageAndGotoRegistration}>
                                            click here
                            </button>&nbsp;to repeat your registration
                            </span>

                            </p>
                        </div>
                    </React.Fragment>}
            </div>
        </div>
    )
};

export const RegisterPageContent = withRouter(RegisterPageContentComponent);