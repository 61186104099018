import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {MSG_USR_PERM} from "../../../../lib/msgUserPermissions";
import {ROUTE_PATHS} from "../../../../lib/constants";

function MenuTreeLink (props) {
    const {msg, category, list} = props;
    const [userPermission, setUserPermission] = useState(null);
    const [treeLink, setTreeLink] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const  user = useSelector(state => state.userReducer);

    useEffect(() => {
        if (user.userPermission) {
            setUserPermission(userPermission);
            let disabled = !MSG_USR_PERM[user.userPermission][category].includes(msg) &&
                user.userPermission !== 'unlimitedActive' &&
                user.userPermission !== 'freeActive';
                setDisabled(disabled);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.userPermission, msg, category]);

    useEffect(() => {
        if (category.startsWith('translatorCbpr')) {
            let res = 'translator/cbpr/' + (category.replace('translatorCbpr', '').toLowerCase());
            setTreeLink(res);
        } else if (category.startsWith('cbpr')) {
            let res = 'cbpr/plus'
            setTreeLink(res);
        } else if (category.startsWith('target')) {
            let res = 'target2'
            setTreeLink(res);
        } else if (category.startsWith('translatorTargetTwo')) {
            let res = 'translator/target2/' + (category.replace('translatorTargetTwo', '').toLowerCase());
            setTreeLink(res);
        } else if (category.startsWith('sicEuroSic')) {
            let res = 'sic/euroSic';
            setTreeLink(res);
        } else if (category.startsWith('scripsMeps')) {
            let res = 'scrips/meps';
            setTreeLink(res);
        } else if (category.startsWith('fedNow')) {
            let res = 'fedNow'
            setTreeLink(res);
        } else {
            setTreeLink(category);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);


    return (
        <div className='tree__bot-level--item'>
            {disabled ?
                <span className='tree__bot-level disabled__node'>
                     {list[msg].title}
                 </span>:
                <NavLink to={'/' + ROUTE_PATHS['checker'] + '/' + treeLink + '/' + msg}
                         activeClassName='tree__bot-level--active'
                         title={list[msg].title}
                         className='tree__bot-level'>
                    {list[msg].title}
                </NavLink>
            }
        </div>
    )
}

export default MenuTreeLink;
