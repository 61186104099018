import React from 'react';
import {RegisterPageContent} from './RegisterPageContent';

const RegisterLazyHelper =(props)=>  {
    return (
        <React.Fragment>
            <RegisterPageContent />
        </React.Fragment>
    );
};

export default RegisterLazyHelper;
