import React, {useEffect} from 'react';
import DOMPurify from "dompurify";
import '../../../assets/css/Crm.css';
import {Helmet} from "react-helmet";
import {SEO_TAGS} from "../../lib/constants";

const QuoteLibrariesPage =()=> {
    const pureHTML = `<div id='crmWebToEntityForm' class='zcwf_lblLeft crmWebToEntityForm' style='background-color: white;color: black;max-width: 365px;'>
<form name=WebToLeads4703922000001130001 method='POST' accept-charset='UTF-8' id='crmFormQuoteLib'>
 <input type='text' style='display:none;' name='xnQsjsdp' value='a09e9953deb016a1a7e42446a506ea4bb5207c89778ad1e7315be647827aaa2d'></input> 
 <input type='hidden' name='zc_gad' id='zc_gad' value=''></input> 
 <input type='text' style='display:none;' name='xmIwtLD' value='3b3095f2812686fca36fd9143eb5ae8ad30d39b946e9c811c88816b15af0b316'></input> 
 <input type='text'  style='display:none;' name='actionType' value='TGVhZHM='></input>
 <input type='text' style='display:none;' name='returnURL' value='${process.env.PUBLIC_URL}/thank-you' > </input>
<div class='zcwf_title' style='max-width: 600px;color: black;'>FINaplo Financial Messages Libraries - Quote</div>
<div class='zcwf_row'><div class='zcwf_col_lab' style='font-size:12px;'><label for='Last_Name'>Name<span style='color:red;'>*</span></label></div><div class='zcwf_col_fld'><input type='text' id='Last_Name' name='Last Name' maxlength='80'></input><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab' style='font-size:12px;'><label for='Email'>Company Email<span style='color:red;'>*</span></label></div><div class='zcwf_col_fld'><input type='text' ftype='email' id='Email' name='Email' maxlength='100'></input><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab' style='font-size:12px;'><label for='LEADCF2'>Library of interest<span style='color:red;'>*</span></label></div><div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' id='LEADCF2' name='LEADCF2'  >
<option value='-None-'>-None-</option>
<option value='swiftMt'>SWIFT MT</option>
<option value='iso20022'>ISO20022</option>
<option value='sepa'>SEPA</option>
<option value='cbprPlus'>CBPR&#x2b;</option>
<option value='cbprPlusTranslator'>SWIFT MT &lt;&gt; CBPR&#x2b; TRANSLATOR</option>
<option value='targetTwo'>TARGET2</option>
<option value='targetTwoTranslator'>SWIFT MT&lt;&gt;TARGET 2 TRANSLATOR</option>
<option value='MEPS&#x2b;'>MEPS&#x2b;</option>
<option value='SIC&#x2f;euroSIC'>SIC&#x2f;euroSIC</option>
<option value='FedNow'>FedNow</option>
<option value='BAHTNET'>BAHTNET</option>
</select><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab' style='font-size:12px;'><label for='Description'>Description<span style='color:red;'>*</span></label></div><div class='zcwf_col_fld'><textarea id='Description' maxlength='150' name='Description'></textarea><div class='zcwf_col_help'></div></div></div><div class='zcwf_row'> <div class='zcwf_col_lab'></div><div class='zcwf_col_fld'><div class='g-recaptcha' data-sitekey='6LfG5OAZAAAAAFDqc2TVW7tU_Hp3KzkBgifOXD3w' data-theme='light' data-callback='rccallback4703922000001130001' id='recap4703922000001130001'></div><div  id='recapErr4703922000001130001' style='font-size:12px;color:red;visibility:hidden;margin-left:-130px;max-width:341px;margin-top:5px;'>Captcha validation failed. If you are not a robot then please try again.</div></div></div><div class='zcwf_row'><div class='zcwf_privacy'><div class='dIB vat' align='left'><div class='displayPurpose  f13'><label class='newCustomchkbox-md dIB w100per'><input autocomplete='off' id='privacyTool4703922000001130001' type='checkbox' name='privacyTool'></label></div></div><div class='dIB zcwf_privacy_txt' style='font-size: 12px;color: black;'>I agree to the <a href='https://www.paymentcomponents.com/privacy-statement/' title='https://www.paymentcomponents.com/privacy-statement/' target='_blank'>Privacy Policy</a>&nbsp;and <a href='https://www.paymentcomponents.com/terms-of-service/' title='https://www.paymentcomponents.com/terms-of-service/' target='_blank'>Terms of Service</a>.</div> <div  id='privacyErr4703922000001130001' style='font-size:12px;color:red;padding-left: 5px;visibility:hidden;'>Please accept this</div></div></div><div class='zcwf_row'><div class='zcwf_col_lab'></div><div class='zcwf_col_fld'><input type='submit' id='formsubmit' class='formsubmit zcwf_button' value='Submit' title='Submit'></div>
</form>
</div>`;

    const safeHTML = DOMPurify.sanitize(pureHTML);

    useEffect(()=> {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <section className='crm'>
            <Helmet>
                <meta name='viewport' content='width=device-width, initial-scale=1.0'/>
                <meta httpEquiv='content-type' content='text/html;charset=UTF-8'/>
                <script src='https://www.google.com/recaptcha/api.js' async defer/>
                <script src={process.env.PUBLIC_URL + '/crm/QuoteLibrariesScript.js'} type="text/javascript" />
                <title>{SEO_TAGS.crm.quote.title}</title>
                <meta name="description" content={SEO_TAGS.crm.quote.description}/>
                <link rel="canonical" href={process.env.PUBLIC_URL + '/quote-messaging-libraries'}/>
                <meta property="og:title"  content={SEO_TAGS.crm.quote.title}/>
                <meta property="og:description" content={SEO_TAGS.crm.quote.description}/>
                <meta property="og:url" content={process.env.PUBLIC_URL + '/quote-messaging-libraries'}/>
                <meta name="twitter:title" content={SEO_TAGS.crm.quote.title}/>
                <meta name="twitter:description" content={SEO_TAGS.crm.quote.description}/>
            </Helmet>
            <article dangerouslySetInnerHTML={{ __html: safeHTML }} />
        </section>
    );
}

export default QuoteLibrariesPage;
