import React, {useState, useRef} from 'react';
import{Overlay, Popover} from 'react-bootstrap';

function InfoTooltip(props) {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const {id, content, placement, label} = props;

    const handleClick = event => {
        setShow(!show);
        setTarget(event.target);
    };

    return (
        <div className='tltp--wrapper' ref={ref}>
            <div className='tltp' onClick={handleClick}>
                <i className={props.icon + ' bg-sprites'}/>
                {label ?
                    <span>
                        {label}
                    </span>
                :null}
            </div>
            <Overlay
                transition={true}
                show={show}
                target={target}
                placement={placement}
                container={ref.current}
                containerPadding={20}>
                <Popover id={id}>
                    <Popover.Content>
                        {content}
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    );
}

export default InfoTooltip;

