import React, {useState} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {LogsTable} from "./LogsTable";
import {MessagesTypeCountChart} from "./MessagesTypeCountChart";
import {MessagesTypeCurrencyChart} from "./MessagesTypeCurrencyChart";

export const LogsChartsTabs = (props) =>  {

const {isLoggedIn, changeRespObject} = props;

const [key, setKey] = useState('charts');

return (
    <Tabs
        id="controlled-tabs"
        className='logs-charts-tabs'
        activeKey={key}
        onSelect={(k) => setKey(k)}>
        <Tab eventKey="logs" title="YOUR MESSAGES LOG">
            <div className='checker__logs'>
                <LogsTable isLoggedIn={isLoggedIn}
                           changeRespObject={changeRespObject} />
            </div>
        </Tab>
        <Tab eventKey="charts" title="YOUR MESSAGES STATS">
            <div className='checker__charts--finaplo-ai'>
            </div>
            <section className='checker__charts'>
                <article className='checker__charts--wrapper checker__charts--wrapper__left'>
                    <MessagesTypeCountChart />
                </article>
                <article className='checker__charts--wrapper checker__charts--wrapper__right'>
                    <MessagesTypeCurrencyChart />
                </article>
            </section>
        </Tab>
    </Tabs>)
}