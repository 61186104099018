import React, {lazy, useEffect, useRef, useState} from 'react';
import store from "../../../../store";
import {getMessagesTypeCurrencyChartData} from "../../../../providers/chartsService";
import {useSelector} from "react-redux";

const ResponsiveSunburst = lazy(() =>
    import("@nivo/sunburst").then(module => ({default: module.ResponsiveSunburst}))
);

export const MessagesTypeCurrencyChart = (props) =>  {
    const [messagesTypeCurrencyData, setMessagesTypeCurrencyData] = useState(null);
    const [fetchingChart, setFetchingChart] = useState(false);
    const [fetchedChart, setFetchedChart] = useState(false);

    const chart = useSelector(state => state.chartsReducer);

    const message = useSelector(state => state.messageReducer);

    const prevMessage = useRef();

    const theme = {
        background: "transparent",
        labels: {
            text: {
                fontSize: "10px",
                fontWeight: "700",
                textShadow: "-1px 1px 1px #21232b"
            }
        }
    };

    useEffect(()=> {
        fetchChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        if (message && message !== prevMessage.current &&
            ((JSON.stringify(message).includes('"fetched":true') &&
                    !JSON.stringify(message).includes('"fetching":true')) ||
                (JSON.stringify(message).includes("'fetched':true'") &&
                    !JSON.stringify(message).includes("'fetching':true'")) ||
                (JSON.stringify(message).includes('fetched:true') &&
                    !JSON.stringify(message).includes('fetching:true'))
            )) {
            let timeout = 0;
            if (message !== prevMessage.current) {
                prevMessage.current = message;
                timeout = 800;
            }
            setTimeout(() => {
                fetchChart();
            }, timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    useEffect(()=> {
        if (chart.messagesTypeCurrency) {
            setFetchingChart(chart.messagesTypeCurrency.fetching);
            setFetchedChart(chart.messagesTypeCurrency.fetched);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[chart.messagesTypeCurrency]);

    const fetchChart = () => {
        store.dispatch(getMessagesTypeCurrencyChartData()).then((resp)=> {
            processData(resp.value.data.aggregations)
        }, (err)=> {
        })
    }

    const processData = (aggr) => {
        const keys = Object.keys(aggr);
        const rawData = aggr[keys[0]].buckets;
        let finalData = {
            name: 'sunburst',
            children: []
        };
        rawData.forEach((msg) => {
            const msgKeys = Object.keys(msg);
            const msgData = msg[msgKeys[2]].buckets;
            const msgName = msg.key === "" ? "Other" : msg.key;
            const otherCount = msg[msgKeys[2]].sum_other_doc_count;
            if (msgData.length > 0) {
                let currencies = msgData.map(curr => {
                    let valueKey = Object.keys(curr)[2];
                    return {
                        name: msg.key +"-" + curr.key,
                        value: curr[valueKey].value ? curr[valueKey].value: 0
                    }
                });
                const lastChild = {name: msg.key + "-Other", value: otherCount};
                currencies = [...currencies, lastChild]
                if (finalData.children.filter(m => m.name === msgName).length  <= 0) {
                    let newMsgEntry = {
                        name: msgName,
                        children: currencies
                    }
                    finalData.children = [...finalData.children, newMsgEntry];
                } else {
                    let existingMsgEntryIdx =  finalData.children.findIndex(m => m.name === (msg.key === "" ? "Other" : msg.key));
                    let existingMsgData = finalData.children[existingMsgEntryIdx].data;
                    finalData.children[existingMsgEntryIdx].data = [...existingMsgData, {
                        name: msgName,
                        children: currencies}]
                }
            }
        })
        setMessagesTypeCurrencyData(finalData);
    }


    return (
        <div className='checker__charts--item checker__charts--type-currency'>
            <h4>Your payment messages per currency</h4>
            { fetchedChart ?
                <React.Fragment>
                    { messagesTypeCurrencyData && messagesTypeCurrencyData.children.length > 0 ?
                        <ResponsiveSunburst
                            data={messagesTypeCurrencyData}
                            margin={{ top: 0, right: 0, bottom: 10, left: 0 }}
                            borderWidth={1}
                            id="name"
                            value="value"
                            cornerRadius={1}
                            borderColor="#333542"
                            colors={{ scheme: 'dark2' }}
                            colorBy="id"
                            childColor={{ from: 'color', modifiers: [ [ 'brighter', 1.2] ] }}
                            enableArcLabels={true}
                            arcLabelsSkipAngle={8}
                            arcLabelsRadiusOffset={0.4}
                            arcLabel={d => {
                                return <React.Fragment>
                                            <tspan x={d.depth > 1 ? (d.arc.startAngle > 2 ? "-0.4em" : "0.6em") : "0"} dy={d.arc.startAngle > 2 ? "-0.4em": "-1em"}>{d.id.includes('-') ? d.id.split('-')[1] : d.id}</tspan>
                                            <tspan x={d.depth > 1 ? (d.arc.startAngle > 2 ? "-0.2em" : "0.8em") : "0"} dy={d.arc.startAngle > 2 ? "1em": "1em"}>{Math.round(d.percentage) + "%"}</tspan>
                                        </React.Fragment>
                            }}
                            // arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'brighter', 5 ] ] }}
                            arcLabelsTextColor="#ffffff"
                            theme={theme}
                            transitionMode="innerRadius"
                            animate={true}
                            tooltip={spot => {
                                return <div style={{padding: '7px', backgroundColor: '#ffffff', fontSize: '12px'}}>
                                            <span style={{display: 'flex', alignItems: 'center'}}>
                                                <i style={{width: '8px', height: '8px',
                                                    backgroundColor: spot.color,
                                                    marginRight: '5px',
                                                    display: 'block'
                                                }} />
                                                <span>
                                                    {(spot.id.includes('-') ? spot.id.split('-')[1] : spot.id) + ": "}
                                                </span>
                                               <span style={{marginLeft: "3px"}}>
                                                   {spot.value ? spot.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'}
                                                </span>
                                            </span>
                                </div>;
                            }}/>
                        :<div className='checker__charts--no-data'>
                            <h5>There are no data to display</h5>
                        </div>}
                </React.Fragment>
                :<React.Fragment>
                    { fetchingChart ?
                        <div className='checker__charts--loading'>
                            <h5>Loading chart...</h5>
                            <div className="spinner loading"/>
                        </div>
                        :<div className='checker__charts--error'>
                            <h5>An error occurred. Unable to fetch chart.</h5>
                        </div>
                    }
                </React.Fragment>
            }
        </div>

    )
}
