import React, {useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Collapse} from "react-bootstrap";
import MenuTreeLink from "./MenuTreeLink";
import {SearchBox} from "./SearchBox";
import {ROUTE_PATHS} from "../../../../lib/constants";

const SideBarComponent = () =>  {
    const [mtMessages, setMtMessages] = useState(null);
    const [mtCategories, setMtCategories] = useState(null);
    const [mxMessages, setMxMessages] = useState(null);
    const [mxCategories, setMxCategories] = useState(null);
    const [sepaEpcMessages, setSepaEpcMessages] = useState(null);
    const [sepaEpcSchemes, setSepaEpcSchemes] = useState(null);
    const [cbprPlusMessages, setCbprPlusMessages] = useState(null);
    const [translatorCbprMtMessages, setTranslatorCbprMtMessages] = useState(null);
    const [translatorCbprMxMessages, setTranslatorCbprMxMessages] = useState(null);
    const [targetTwoMessages, setTargetTwoMessages] = useState(null);
    const [sicEuroSicMessages, setSicEuroSicMessages] = useState(null);
    const [scripsMepsMessages, setScripsMepsMessages] = useState(null);
    const [translatorTargetTwoMtMessages, setTranslatorTargetTwoMtMessages] = useState(null);
    const [translatorTargetTwoMxMessages, setTranslatorTargetTwoMxMessages] = useState(null);
    const [fedNowMessages, setFedNowMessages] = useState(null);
    const [mtOpen, setMtOpen] = useState(false);
    const [mxOpen, setMxOpen] = useState(false);
    const [sepaOpen, setSepaOpen] = useState(false);
    const [transCbprOpen, setTransCbprOpen] = useState(false);
    const [cbprPlusOpen, setCbprPlusOpen] = useState(false);
    const [targetTwoOpen, setTargetTwoOpen] = useState(false);
    const [sicEuroSicOpen, setSicEuroSicOpen] = useState(false);
    const [scripsMepsOpen, setScripsMepsOpen] = useState(false);
    const [transTargetTwoOpen, setTransTargetTwoOpen] = useState(false);
    const [fedNowOpen, setFedNowOpen] = useState(false);
    const [openMtSeries, setOpenMtSeries] = useState('');
    const [openMxSeries, setOpenMxSeries] = useState('');
    const [openSepaEpcSeries, setOpenSepaEpcSeries] = useState('');

    import ('../../../../lib/finMessagesSamples/mt_tree').then(tree => {
        setMtMessages(tree.MT_MESSAGES);
        setMtCategories(tree.MT_CATEGORIES);
    });

    import ('../../../../lib/finMessagesSamples/mx_tree').then(tree => {
        setMxMessages(tree.MX_MESSAGES);
        setMxCategories(tree.MX_CATEGORIES);
    });

    import ('../../../../lib/finMessagesSamples/sepa_tree').then(tree => {
        setSepaEpcMessages(tree.SEPA_EPC_CT_MESSAGES);
        setSepaEpcSchemes(tree.SEPA_EPC_SCHEMES);
    });

    import ('../../../../lib/finMessagesSamples/cbpr_plus_tree').then(tree => {
        setCbprPlusMessages(tree.CBPR_PLUS_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/translator_cbpr_mt_tree').then(tree => {
        setTranslatorCbprMtMessages(tree.TRANSLATOR_CBPR_MT_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/translator_cbpr_mx_tree').then(tree => {
        setTranslatorCbprMxMessages(tree.TRANSLATOR_CBPR_MX_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/target_two_tree').then(tree => {
        setTargetTwoMessages(tree.TARGET_TWO_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/sic_eurosic_tree').then(tree => {
        setSicEuroSicMessages(tree.SIC_EUROSIC_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/scrips_meps_tree').then(tree => {
        setScripsMepsMessages(tree.SCRIPS_MEPS_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/translator_target_two_mt_tree').then(tree => {
        setTranslatorTargetTwoMtMessages(tree.TRANSLATOR_TARGET_TWO_MT_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/translator_target_two_mx_tree').then(tree => {
        setTranslatorTargetTwoMxMessages(tree.TRANSLATOR_TARGET_TWO_MX_MESSAGES);
    });

    import ('../../../../lib/finMessagesSamples/fednow_tree').then(tree => {
        setFedNowMessages(tree.FEDNOW_MESSAGES);
    });

    return (
        <aside className='left__part'>
            <nav>
                <div className='aside-nav__content'>
                    <SearchBox />
                    <div className="tree">
                        <h3 className='tree__title'>VALIDATION</h3>
                        <Link to={'/' + ROUTE_PATHS['checker'] + '/mt'}
                              className='tree__top-level'
                              onClick={()=> {
                                  setMtOpen(!mtOpen);
                                  setMxOpen(false);
                                  setSepaOpen(false);
                                  setTransCbprOpen(false);
                                  setCbprPlusOpen(false);
                                  setTargetTwoOpen(false);
                                  setSicEuroSicOpen(false);
                                  setScripsMepsOpen(false);
                                  setTransTargetTwoOpen(false);
                                  setFedNowOpen(false);
                              }}>
                            <i className={'bg-sprites tree__icon' +
                            (mtOpen ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                            <span>SWIFT MT</span>
                        </Link>
                        <Collapse in={mtOpen} dimension= "height">
                            {mtMessages && mtCategories ?
                                <div className='tree__mid-level--wrapper__outer'>
                                    <div className='tree__mid-level--wrapper'>
                                    {Object.keys(mtCategories).map((c)=> {
                                     return <div className='tree__mid-level--item' key={'mt-categ-key-' + c}>
                                                <div className='tree__mid-level'
                                                    onClick={(e)=> {
                                                        setOpenMtSeries(openMtSeries === c ? '' : c)
                                                    }}>
                                                    <i className={'bg-sprites tree__icon' +
                                                        (openMtSeries === c ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                                                    <span>
                                                        {mtCategories[c].title}
                                                    </span>
                                                </div>
                                                 <Collapse in={openMtSeries === c} dimension= "height">
                                                     <div className='tree__bot-level--wrapper'>
                                                         {mtCategories[c].messages.map((m) => {
                                                            return <MenuTreeLink msg={m} category={'mt'}
                                                                                 list={mtMessages}
                                                                                 key={'msg-key-mt-' + m}/>
                                                         })}
                                                     </div>
                                                 </Collapse>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            :<div></div>}
                        </Collapse>
                        <Link to={'/' + ROUTE_PATHS['checker'] + '/mx'}
                              className='tree__top-level'
                              onClick={()=> {
                                  setMxOpen(!mxOpen);
                                  setMtOpen(false);
                                  setSepaOpen(false);
                                  setTransCbprOpen(false);
                                  setCbprPlusOpen(false);
                                  setTargetTwoOpen(false);
                                  setSicEuroSicOpen(false);
                                  setScripsMepsOpen(false);
                                  setTransTargetTwoOpen(false);
                                  setFedNowOpen(false);
                              }}>
                            <i className={'bg-sprites tree__icon' +
                            (mxOpen ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                            <span>ISO20022</span>
                        </Link>
                        <Collapse in={mxOpen} dimension= "height">
                            {mxMessages && mxCategories  ?
                                <div className='tree__mid-level--wrapper__outer'>
                                    <div className='tree__mid-level--wrapper'>
                                        {Object.keys(mxCategories).map((c)=> {
                                            return <div className='tree__mid-level--item' key={'mx-categ-key-' + c}>
                                                <div className='tree__mid-level'
                                                     onClick={(e)=> {
                                                         setOpenMxSeries(openMxSeries === c ? '' : c)
                                                     }}>
                                                    <i className={'bg-sprites tree__icon' +
                                                    (openMxSeries === c ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                                                    <span>
                                                        {mxCategories[c].title}
                                                    </span>
                                                </div>
                                                <Collapse in={openMxSeries === c} dimension= "height">
                                                    <div className='tree__bot-level--wrapper'>
                                                        {mxCategories[c].messages.map((m) => {
                                                            return <MenuTreeLink
                                                                msg={m}
                                                                category={'mx'}
                                                                list={mxMessages}
                                                                key={'msg-key-mx-' + m}
                                                            />
                                                        })}
                                                    </div>
                                                </Collapse>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            :<div></div>}
                        </Collapse>
                        <Link to={'/' + ROUTE_PATHS['checker'] + '/sepa'}
                              className='tree__top-level'
                              onClick={()=> {
                                  setSepaOpen(!sepaOpen);
                                  setMxOpen(false);
                                  setMtOpen(false);
                                  setTransCbprOpen(false);
                                  setCbprPlusOpen(false);
                                  setTargetTwoOpen(false);
                                  setSicEuroSicOpen(false);
                                  setScripsMepsOpen(false);
                                  setTransTargetTwoOpen(false);
                                  setFedNowOpen(false);
                              }}>
                            <i className={'bg-sprites tree__icon' +
                            (sepaOpen ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                            <span>SEPA</span>
                        </Link>
                        <Collapse in={sepaOpen} dimension= "height">
                            {sepaEpcMessages && sepaEpcSchemes ?
                                <div className='tree__mid-level--wrapper__outer'>
                                    <div className='tree__mid-level--wrapper'>
                                      {Object.keys(sepaEpcSchemes).map((c)=> {
                                        return <div className='tree__mid-level--item' key={'sepa-epc-scheme-key-' + c}>
                                          <div className='tree__mid-level'
                                               onClick={(e)=> {
                                                 setOpenSepaEpcSeries(openSepaEpcSeries === c ? '' : c)
                                               }}>
                                            <i className={'bg-sprites tree__icon' +
                                                (openSepaEpcSeries === c ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                                            <span>
                                                        {sepaEpcSchemes[c].title}
                                            </span>
                                          </div>
                                          <Collapse in={openSepaEpcSeries === c} dimension= "height">
                                            <div className='tree__bot-level--wrapper'>
                                              {sepaEpcSchemes[c].messages.map((m) => {
                                                return <MenuTreeLink msg={m} category={'sepa'}
                                                                     list={sepaEpcMessages}
                                                                     key={'msg-key-sepa-' + m}/>
                                              })}
                                            </div>
                                          </Collapse>
                                        </div>
                                      })}
                                    </div>
                                </div>
                            :<div></div>}
                        </Collapse>
                        <Link to={'/' + ROUTE_PATHS['checker'] + '/cbpr/plus'}
                              className='tree__top-level'
                              onClick={()=> {
                                  setCbprPlusOpen(!cbprPlusOpen);
                                  setMxOpen(false);
                                  setMtOpen(false);
                                  setSepaOpen(false);
                                  setTransCbprOpen(false);
                                  setTargetTwoOpen(false);
                                  setSicEuroSicOpen(false);
                                  setScripsMepsOpen(false);
                                  setTransTargetTwoOpen(false);
                                  setFedNowOpen(false);
                              }}>
                            <i className={'bg-sprites tree__icon' +
                            (cbprPlusOpen ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                            <span>{'CBPR+'}</span>
                        </Link>
                        <Collapse in={cbprPlusOpen} dimension= "height">
                            {cbprPlusMessages ?
                                <div className='tree__mid-level--wrapper__outer'>
                                    <div className='tree__mid-level--wrapper'>
                                        {Object.keys(cbprPlusMessages).sort().map((m) => {
                                            return <MenuTreeLink msg={m}
                                                                 category={'cbprPlus'}
                                                                 list={cbprPlusMessages}
                                                                 key={'msg-key-cbpr-plus-' + m}
                                            />
                                        })}
                                    </div>
                                </div>
                            :<div></div>}
                        </Collapse>
                        <Link to={'/' + ROUTE_PATHS['checker'] + '/target2'}
                              className='tree__top-level'
                              onClick={()=> {
                                  setTargetTwoOpen(!targetTwoOpen)
                                  setMxOpen(false);
                                  setMtOpen(false);
                                  setSepaOpen(false);
                                  setCbprPlusOpen(false);
                                  setTransCbprOpen(false);
                                  setSicEuroSicOpen(false);
                                  setScripsMepsOpen(false);
                                  setTransTargetTwoOpen(false);
                                  setFedNowOpen(false);
                              }}>
                            <i className={'bg-sprites tree__icon' +
                            (targetTwoOpen ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                            <span>{'TARGET2'}</span>
                        </Link>
                        <Collapse in={targetTwoOpen} dimension= "height">
                            {targetTwoMessages ?
                                <div className='tree__mid-level--wrapper__outer'>
                                    <div className='tree__mid-level--wrapper'>
                                        {Object.keys(targetTwoMessages).sort().map((m) => {
                                            return <MenuTreeLink msg={m}
                                                                 category={'targetTwo'}
                                                                 list={targetTwoMessages}
                                                                 key={'msg-key-target-two-' + m}
                                            />
                                        })}
                                    </div>
                                </div>
                            :<div></div>}
                        </Collapse>
                        <Link to={'/' + ROUTE_PATHS['checker'] + '/sic/euroSic'}
                              className='tree__top-level'
                              onClick={()=> {
                                  setSicEuroSicOpen(!sicEuroSicOpen);
                                  setMxOpen(false);
                                  setMtOpen(false);
                                  setSepaOpen(false);
                                  setCbprPlusOpen(false);
                                  setTransCbprOpen(false);
                                  setTargetTwoOpen(false);
                                  setScripsMepsOpen(false);
                                  setTransTargetTwoOpen(false);
                                  setTransTargetTwoOpen(false);
                                  setFedNowOpen(false);
                              }}>
                            <i className={'bg-sprites tree__icon' +
                                (sicEuroSicOpen ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                            <span>{'SIC/euroSIC'}</span>
                        </Link>
                        <Collapse in={sicEuroSicOpen} dimension= "height">
                            {sicEuroSicMessages ?
                                <div className='tree__mid-level--wrapper__outer'>
                                    <div className='tree__mid-level--wrapper'>
                                        {Object.keys(sicEuroSicMessages).sort().map((m) => {
                                            return <MenuTreeLink msg={m}
                                                                 category={'sicEuroSic'}
                                                                 list={sicEuroSicMessages}
                                                                 key={'msg-key-sic-eurosic-' + m}
                                            />
                                        })}
                                    </div>
                                </div>
                                :<div></div>}
                        </Collapse>
                        <Link to={'/' + ROUTE_PATHS['checker'] + '/scrips/meps'}
                              className='tree__top-level'
                              onClick={()=> {
                                  setScripsMepsOpen(!scripsMepsOpen);
                                  setMxOpen(false);
                                  setMtOpen(false);
                                  setSepaOpen(false);
                                  setCbprPlusOpen(false);
                                  setTransCbprOpen(false);
                                  setTargetTwoOpen(false);
                                  setSicEuroSicOpen(false);
                                  setTransTargetTwoOpen(false);
                                  setTransTargetTwoOpen(false);
                                  setFedNowOpen(false);
                              }}>
                            <i className={'bg-sprites tree__icon' +
                                (scripsMepsOpen ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                            <span>{'SCRIPS (MEPS+)'}</span>
                        </Link>
                        <Collapse in={scripsMepsOpen} dimension= "height">
                            {scripsMepsMessages ?
                                <div className='tree__mid-level--wrapper__outer'>
                                    <div className='tree__mid-level--wrapper'>
                                        {Object.keys(scripsMepsMessages).sort().map((m) => {
                                            return <MenuTreeLink msg={m}
                                                                 category={'scripsMeps'}
                                                                 list={scripsMepsMessages}
                                                                 key={'msg-key-scrips-meps-' + m}
                                            />
                                        })}
                                    </div>
                                </div>
                                :<div></div>}
                        </Collapse>
                        <Link to={'/' + ROUTE_PATHS['checker'] + '/fedNow'}
                              className='tree__top-level'
                              onClick={()=> {
                                  setFedNowOpen(!fedNowOpen);
                                  setMxOpen(false);
                                  setMtOpen(false);
                                  setSepaOpen(false);
                                  setTransCbprOpen(false);
                                  setCbprPlusOpen(false);
                                  setSicEuroSicOpen(false);
                                  setScripsMepsOpen(false);
                                  setTargetTwoOpen(false);
                                  setTransTargetTwoOpen(false);
                              }}>
                            <i className={'bg-sprites tree__icon' +
                                (fedNowOpen ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                            <span>FEDNOW</span>
                        </Link>
                        <Collapse in={fedNowOpen} dimension= "height">
                            {fedNowMessages ?
                                <div className='tree__mid-level--wrapper__outer'>
                                    <div className='tree__mid-level--wrapper'>
                                        {Object.keys(fedNowMessages).sort().map((m) => {
                                            return <MenuTreeLink msg={m}
                                                                 category={'fedNow'}
                                                                 list={fedNowMessages}
                                                                 key={'msg-key-fednow-' + m}
                                            />
                                        })}
                                    </div>
                                </div>
                                :<div></div>}
                        </Collapse>
                        <h3 className='tree__title'>TRANSLATION</h3>
                        <Link to={'/' + ROUTE_PATHS['checker'] + '/translator/cbpr/mt'}
                              className='tree__top-level'
                              onClick={()=> {
                                  setTransCbprOpen(!transCbprOpen);
                                  setMxOpen(false);
                                  setMtOpen(false);
                                  setSepaOpen(false);
                                  setCbprPlusOpen(false);
                                  setTargetTwoOpen(false);
                                  setSicEuroSicOpen(false);
                                  setScripsMepsOpen(false);
                                  setTransTargetTwoOpen(false);
                                  setFedNowOpen(false);
                              }}>
                            <i className={'bg-sprites tree__icon' +
                                (transCbprOpen ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                            <span className={'trans-span'}>{'SWIFT '}</span>
                            <span >{'MT <> CBPR+ Translation'}</span>
                        </Link>
                        <Collapse in={transCbprOpen} dimension= "height">
                            {translatorCbprMtMessages && translatorCbprMxMessages ?
                                <div className='tree__mid-level--wrapper__outer'>
                                    <div className='tree__mid-level--wrapper'>
                                        {Object.keys(translatorCbprMtMessages).sort().map((m) => {
                                            return <MenuTreeLink msg={m}
                                                                 category={'translatorCbprMt'}
                                                                 list={translatorCbprMtMessages}
                                                                 key={'msg-key-trans-cbpr-mt-' + m}
                                            />
                                        })}
                                        {Object.keys(translatorCbprMxMessages).sort().map((m) => {
                                            return <MenuTreeLink msg={m}
                                                                 category={'translatorCbprMx'}
                                                                 list={translatorCbprMxMessages}
                                                                 key={'msg-key-trans-cbpr-mx-' + m}
                                            />
                                        })}
                                    </div>
                                </div>
                                :<div></div>}
                        </Collapse>
                        <Link to={'/' + ROUTE_PATHS['checker'] + '/translator/target2/mt'}
                              className='tree__top-level'
                              onClick={()=> {
                                  setTransTargetTwoOpen(!transTargetTwoOpen);
                                  setMxOpen(false);
                                  setMtOpen(false);
                                  setSepaOpen(false);
                                  setCbprPlusOpen(false);
                                  setTransCbprOpen(false);
                                  setTargetTwoOpen(false);
                                  setSicEuroSicOpen(false);
                                  setScripsMepsOpen(false);
                                  setFedNowOpen(false);
                              }}>
                            <i className={'bg-sprites tree__icon' +
                            (transTargetTwoOpen ? ' bg-tree-expanded' : ' bg-tree-collapsed')}/>
                            <span className={'trans-span'}>{'SWIFT '}</span>
                            <span >{'MT <> TARGET2 Translation'}</span>
                        </Link>
                        <Collapse in={transTargetTwoOpen} dimension= "height">
                            {translatorTargetTwoMtMessages && translatorTargetTwoMxMessages ?
                                <div className='tree__mid-level--wrapper__outer'>
                                    <div className='tree__mid-level--wrapper'>
                                        {Object.keys(translatorTargetTwoMtMessages).sort().map((m) => {
                                            return <MenuTreeLink msg={m}
                                                                 category={'translatorTargetTwoMt'}
                                                                 list={translatorTargetTwoMtMessages}
                                                                 key={'msg-key-trans-target2-mt-' + m}
                                            />
                                        })}
                                        {Object.keys(translatorTargetTwoMxMessages).sort().map((m) => {
                                            return <MenuTreeLink msg={m}
                                                                 category={'translatorTargetTwoMx'}
                                                                 list={translatorTargetTwoMxMessages}
                                                                 key={'msg-key-trans-target2-mx-' + m}
                                            />
                                        })}
                                    </div>
                                </div>
                                :<div></div>}
                        </Collapse>
                    </div>
                </div>
            </nav>
        </aside>
    );
};

export const SideBar = withRouter(SideBarComponent);
