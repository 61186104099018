import React from 'react';
import {CheckerPageContent} from './CheckerPageContent';

const CheckerLazyHelper =(props)=>  {
    const {messageGroup} = props;
    return (
        <React.Fragment>
            <CheckerPageContent messageGroup={messageGroup} />
        </React.Fragment>
    );
};

export default CheckerLazyHelper;
