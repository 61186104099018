import React, {useEffect, useRef, useState} from 'react';
import {withRouter} from "react-router-dom";
import store from "../../../../store";
import {useSelector} from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {findLog, getLogsList} from "../../../../providers/logsService";
import {
    arrangeSizePerPageList, defineLogRoute, logActionFormatter,
    logDateTimeFormatter,
    logIdFormatter, logResultFormatter,
    logsSortProps, rowClasses, selectRow,
} from "../../../../lib/logsFunc";
import {Button, Dropdown} from "react-bootstrap";
import Select from 'react-select';


const LogsTableComponent = (props) =>  {
    const {history} = props;
    const [logsTblData, setLogsTblData] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [orderBy, setOrderBy] = useState('logEntryTime');
    const [orderDirection, setOrderDirection] = useState('DESC');
    const [paginationOptions, setPaginationOptions] = useState(null);
    const [tblError, setTblError] = useState(null);
    const [paginatorArr, setPaginatorArr] = useState(null);
    const [disabledApisRows, setDisabledApisRows] = useState([]);

    const logs = useSelector(state => state.logsReducer);

    const message = useSelector(state => state.messageReducer);

    const prevPageSize = useRef();
    const prevPageNumber = useRef();
    const prevOrderDirection = useRef();
    const prevMessage = useRef();


    const handleTableChange = (type, newTableState) => {
        if (newTableState) {
            setPageNumber(newTableState.page);
            setPageSize(newTableState.sizePerPage);
            if (type && type === 'sort') {
                setOrderBy(newTableState.sortField);
                setOrderDirection(newTableState.sortOrder.toUpperCase())
            }
        }
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (!row.isApi && row.action && row.message && row.level) {
                if (row.level === 'Error') {
                    store.dispatch(findLog(row.id, row.level.toUpperCase())).then((response)=> {
                        defineLogReponse(row, response.value.data.message);
                    });
                } else {
                    defineLogReponse(row)
                }
            }
        }
    };

    const defineLogReponse =(log, error)=> {
        const msgLogContent = defineLogRoute(log.action, log.logEntryTime, log.message, log.level, log.messageType, error);
        history.push({
            pathname: msgLogContent.route,
            state: {
                logData: msgLogContent
            }
        });
    }

    // useEffect(()=> {
    //      fetchLogs(10, 0, 'logEntryTime', 'DESC');
    // }, []);

    useEffect(()=> {
        if (pageSize !== prevPageSize.current || pageNumber !== prevPageNumber.current ||
            orderDirection !== prevOrderDirection.current || (message && message !== prevMessage.current &&
                ((JSON.stringify(message).includes('"fetched":true') &&
                    !JSON.stringify(message).includes('"fetching":true')) ||
                (JSON.stringify(message).includes("'fetched':true'") &&
                    !JSON.stringify(message).includes("'fetching':true'")) ||
                (JSON.stringify(message).includes('fetched:true') &&
                    !JSON.stringify(message).includes('fetching:true'))
            ))) {
            if (pageSize !== prevPageSize.current) {
                prevPageSize.current = pageSize;
            }
            if (pageNumber !== prevPageNumber.current) {
                prevPageNumber.current = pageNumber;
            }
            if (orderDirection !== prevOrderDirection.current) {
                prevOrderDirection.current = orderDirection;
            }
            let timeout = 0;
            if (message !== prevMessage.current) {
                prevMessage.current = message;
                timeout = 800;
            }
            setTimeout(() => {
                fetchLogs(pageSize, pageNumber - 1, orderBy, orderDirection);
            }, timeout);
        }

    }, [pageSize, pageNumber, orderBy, orderDirection, message]);


    useEffect(()=> {
        if (logs && logs.list && logs.list.payload) {
            setLogsTblData(logs.list.payload);
           const apisRowsIndexes = logs.list.payload.content.map((log)=> {
              return log.isApi ? log.index - 1 : null
           }).filter((res)=> {
               return res != null
           });
            setDisabledApisRows(apisRowsIndexes);
        }
    }, [logs]);


    useEffect(()=> {
        if (logsTblData) {
            let options = {
                custom: true,
                hideSizePerPage: logsTblData.totalPages < pageSize,
                paginationSize: 3,
                pageStartIndex: 1,
                alwaysShowAllBtns: true, // Always show next and previous button
                withFirstAndLast: true,
                hidePageListOnlyOnePage: true,
                showTotal: true,
                paginationTotalRenderer: customTotal,
                disablePageTitle: true,
                page: pageNumber,
                sizePerPage: pageSize,
                totalSize: logsTblData.totalElements,
                sizePerPageList: arrangeSizePerPageList(logsTblData.totalElements)
            };
            setPaginationOptions(options);
            if (logsTblData.totalPages && logsTblData.pageNumber) {
                let arr = arrangePaginatorArr(logsTblData.totalPages, logsTblData.pageNumber);
                setPaginatorArr(arr)
            }
        }

    }, [logsTblData, pageSize, pageNumber]);


    const fetchLogs =(pageSize, pageNumber, orderBy, orderDirection)=> {
        store.dispatch(getLogsList(pageSize, pageNumber, orderBy, orderDirection)).then(()=> {
        }, (err)=> {
            setTblError('An error occurred, unable to fetch logs.')
        })
    }

    const sortCaret =(order, column)=> {
        return <React.Fragment>
                    <span className={order ==='asc' ? "dropup" : "dropdown"}>
                        <span className="caret">
                        </span>
                     </span>
                </React.Fragment>
    }

    const columns = [{
        dataField: 'index',
        text: '#'
    }, {
        dataField: 'id',
        text: 'Id',
        formatter: logIdFormatter
    }, {
        dataField: 'logEntryTime',
        text: 'Date & Time',
        sort: true,
        sortCaret: sortCaret,
        headerFormatter: logsSortProps.headerFormatter,
        formatter: logDateTimeFormatter
    }, {
        dataField: 'messageType',
        text: 'Message Type'
    },
        {
            dataField: 'action',
            text: 'Action',
            formatter: logActionFormatter
        },
        {
            dataField: 'level',
            text: 'Result',
            formatter: logResultFormatter
        }];

    const handlePageChange=(props, type, last, page)=> {
        switch(type) {
            case 'first':
                props.onPageChange(1);
                break;
            case 'prev':
                props.onPageChange(props.page - 1);
                break;
            case 'page':
                props.onPageChange(page);
                break;
            case 'next':
                props.onPageChange(props.page + 1);
                break;
            case 'last':
                props.onPageChange(last);
                break;
            case 'jump':
                props.onPageChange(page);
                break;
            default:
                props.onPageChange(props.page);
        }
    }

    const handleSizePerPage = ({
                                   page,
                                   onSizePerPageChange
                               }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, 1);
    }


    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Total results: { size }
        </span>
    );

    const renderSizePerPageContent =(total)=> {
        const sizesArr = [10, 25, 50, 100];
        // eslint-disable-next-line array-callback-return
        let content = sizesArr.map((size)=> {
            if (total >=size) {
                return (
                    <Dropdown.Item eventKey={size}
                                   key={'key-size-page-' + size}
                                   onClick={(e)=>{e.preventDefault()}}
                                    // eslint-disable-next-line no-script-url
                                   href="!#">
                        {size}
                    </Dropdown.Item>
                )
            }
        });

        return <Dropdown.Menu>
                {content}
            </Dropdown.Menu>

    }

    const arrangePaginatorArr =(total, page)=> {
        if (total >= 5) {
            return[-2, -1, 0, 1, 2];
        } else {
            return Array.from({length: total}, (_, i) => i - 2)
        }
    }

    const arrangeSelectedPage =(page, total)=> {
        return page === 1 ? 2 : (page === 2 ? 1 : ((total - page <= 1) ? -2 : 0))
    }

    const renderJumpToPageContent =(pages)=> {
        let arr = Array.from({length: pages}, (_, i) => i + 1)
        return arr.map((p)=> {
            return {
                value: p,
                label: p
            }
        });
    }

    const handleJumpToPage =(props, page)=> {
        handlePageChange(props,'jump', null, parseInt(page.value));
    }

    return (
       <section>
           {logsTblData && paginationOptions && (logs && logs.list && logs.list.fetched) ?
               <React.Fragment>
                   <PaginationProvider
                       pagination={ paginationFactory(paginationOptions) }>
                           {({
                                    paginationProps,
                                    paginationTableProps
                                }) => (
                                    <article>
                                        <BootstrapTable keyField='id'
                                                        data={ logsTblData.content }
                                                        columns={ columns }
                                                        pagination={ paginationFactory(paginationOptions) }
                                                        remote
                                                        rowClasses={ rowClasses }
                                                        rowEvents={ rowEvents }
                                                        selectRow={ selectRow (disabledApisRows) }
                                                        { ...paginationTableProps }
                                                        onTableChange={ handleTableChange }
                                                        noDataIndication={ () => <h5 className='checker__logs--no-data'> There are no logs available</h5> }
                                            />
                                        {logsTblData.content && logsTblData.content.length > 0 ?
                                            <div className='custom-pagination'>
                                                <div className='custom-pagination__rows-per-page'>
                                                    {logsTblData.totalElements} {' Calls'}
                                                </div>
                                                {logsTblData.totalElements > 10 ?
                                                    <div className='custom-pagination__rows-per-page'>
                                                    <span>
                                                        Show:
                                                    </span>
                                                        <Dropdown  onSelect={(e)=> {
                                                            handleSizePerPage(paginationProps, e)
                                                        }}>
                                                            <Dropdown.Toggle variant="light" id="rows-per-page">
                                                                {paginationProps.sizePerPage}
                                                            </Dropdown.Toggle>
                                                            {renderSizePerPageContent(logsTblData.totalElements)}
                                                        </Dropdown>
                                                    </div>:null}
                                                <div className='custom-pagination__paginator'>
                                                    <div className='custom-pagination__paginator--content'>
                                                        <Button className='custom-pagination__btn custom-pagination__first'
                                                                key={'first-page-paginator'}
                                                                title='First page'
                                                                variant="light"
                                                                disabled={pageNumber.toString() === '1'}
                                                                onClick={()=>{handlePageChange(paginationProps, 'first')}}
                                                        >
                                                            <i/>
                                                            <i/>
                                                        </Button>
                                                        <Button className='custom-pagination__btn custom-pagination__previous'
                                                                key={'prev-page-paginator'}
                                                                title='Previous page'
                                                                variant="light"
                                                                disabled={pageNumber.toString() === '1'}
                                                                onClick={()=>{handlePageChange(paginationProps, 'prev')}}
                                                        >
                                                            <i/>
                                                        </Button>
                                                        { paginatorArr && paginatorArr.length > 0 ?
                                                            <React.Fragment>
                                                                {
                                                                    paginatorArr.map((page) => {
                                                                        return <Button
                                                                            className='custom-pagination__btn custom-pagination__page'
                                                                            key={'number-page-paginator-' + page}
                                                                            title={paginationProps.page + page + (arrangeSelectedPage(paginationProps.page, logsTblData.totalPages))}
                                                                            variant={(paginationProps.page + page + (arrangeSelectedPage(paginationProps.page, logsTblData.totalPages)))===logsTblData.pageNumber ? "primary" : "light"}
                                                                            onClick={() => {
                                                                                handlePageChange(paginationProps, 'page', logsTblData.totalPages, (paginationProps.page + page + arrangeSelectedPage(paginationProps.page, logsTblData.totalPages)))
                                                                            }}
                                                                        >
                                                                   <span>
                                                                       {paginationProps.page + page + (arrangeSelectedPage(paginationProps.page, logsTblData.totalPages))}
                                                                   </span>
                                                                        </Button>
                                                                    })
                                                                }
                                                            </React.Fragment>:null
                                                        }
                                                        <Button className='custom-pagination__btn custom-pagination__next'
                                                                key={'next-page-paginator'}
                                                                title='Next page'
                                                                variant="light"
                                                                disabled={pageNumber && logsTblData && logsTblData.totalPages && pageNumber.toString() === logsTblData.totalPages.toString()}
                                                                onClick={()=>{handlePageChange(paginationProps, 'next')}}
                                                        >
                                                            <i/>
                                                        </Button>
                                                        <Button className='custom-pagination__btn custom-pagination__last'
                                                                key={'last-page-paginator'}
                                                                title='Last page'
                                                                variant="light"
                                                                disabled={pageNumber && logsTblData && logsTblData.totalPages && pageNumber.toString() === logsTblData.totalPages.toString()}
                                                                onClick={()=>{handlePageChange(paginationProps, 'last', logsTblData.totalPages)}}>
                                                            <i/>
                                                            <i/>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className='custom-pagination__pages'>
                                                    <div className='custom-pagination__pages--jump-to'>
                                                        <span> Jump to page: </span>
                                                        <Select
                                                            value={pageNumber}
                                                            placeholder={pageNumber}
                                                            onChange={(e)=>{handleJumpToPage(paginationProps, e)}}
                                                            options={renderJumpToPageContent(logsTblData.totalPages)}
                                                            // defaultMenuIsOpen={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            :null}
                                    </article>
                                )
                           }
                       </PaginationProvider>
               </React.Fragment>
           :null}
           {tblError && (!logs || !logs.list || (!logs.list.fetched)) ?
               <h5 className='checker__logs--error'>
                   {tblError}
               </h5>:null
           }
           {logs && logs.list && logs.list.fetching ?
               <div className='checker__logs--loading'>
                    <h5>Fetching logs...</h5>
                    <div className="spinner loading"/>
               </div>
               :null
           }
       </section>
    )
}

export const LogsTable = withRouter(LogsTableComponent);
