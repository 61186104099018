import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../../../../App';
import {useForm} from "react-hook-form";
import store from "../../../../../store";
import {
    loginUser,
    sendResetPasswordEmail,
    sendNewPassword,
    disconnectActiveSession
} from "../../../../../providers/authService";
import {checkEmptyStr, sendGaEvent} from "../../../../../lib/utils";
import {Link, withRouter} from "react-router-dom";
import {AUTH_RESPONSES, GA_EVENTS, pwPopoverMsg, ROUTE_PATHS} from "../../../../../lib/constants";
import {getUserProfile} from "../../../../../providers/userService";
import InfoTooltip from "../../../../uiElements/InfoTooltip";
import {useSelector} from "react-redux";
import {LoginLinkedin} from "./LoginLinkedin";
import AcceptTerms from "../AcceptTerms";

export const LoginPageContentComponent = (props) => {
    const {update, location, history} = props;
    const {state, dispatch} = useContext(AppContext);
    const {handleSubmit, register, watch, errors, getValues} = useForm();
    const [termsChecked, setTermsChecked] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const [activeSessionMsg, setActiveSessionMsg] = useState(false);
    const [fadeActiveSessionMsg, setFadeActiveSessionMsg] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [forgotPasswordStep, setForgotPasswordStep] = useState(0);
    const [resetPwResponseMsg, setResetPwResponseMsg] = useState('');
    const [containerClsName, setContainerClsName] = useState(null);
    const [resetEmailError, setResetEmailError] = useState(false);
    const [resetPasswordError, setResetPasswordError] = useState(false);
    const [showNewPwError, setShowNewPwError] = useState(true);
    const [showRepeatNewPwError, setShowRepeatNewPwError] = useState(true);

    const [precedentPath, setPrecedentPath] = useState(null);
    const [pathName, setPathName] = useState(null);


    const  user = useSelector(state => state.userReducer);


    useEffect(() => {
        const params = props.match.params;
        if (Object.entries(params).length > 0
            && params.constructor === Object
            && params.email
            && params.confirmationCode) {
            dispatch({
                ...state,
                emailParam: params.email,
                confirmationCodeParam: params.confirmationCode,
                renderResetPw: true
            });
        } else {
            dispatch({
                ...state,
                emailParam: null,
                confirmationCodeParam: null
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    useEffect(() => {
        if (user.userLoggedIn) {
            if (precedentPath) {
                history.push(precedentPath);
            } else {
                history.push('/' + ROUTE_PATHS['checker']);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.userLoggedIn]);


    useEffect(()=> {
        if (location) {
            if (location.pathname) {
                setPathName(location.pathname);
            }
            if (location.state && location.state.from) {
                setPrecedentPath(location.state.from);
            }
        }
    }, [location]);

    useEffect(() =>{
        if (!state.renderResetPw) {
            setForgotPasswordStep(0);
        } else {
            if (state.emailParam && state.confirmationCodeParam) {
                setForgotPasswordStep(3);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        let clsName = "auth__login" + (forgotPasswordStep === 0 ? " auth__login not-forgot": " forgot");
        setContainerClsName(clsName);
    },[forgotPasswordStep]);

    const fetchUserProfile =()=> {
        store.dispatch(getUserProfile('/me')).then((response)=>{
            update(response.action.payload.data.email);
        });
    }

    const onSubmitLogin = values => {
        store.dispatch(loginUser('/authenticate', values)).then((response) => {
            fetchUserProfile();
        }, (error) => {
            // console.log(error.response)
            if (error.response && error.response.status) {
                if (error.response.status.toString().startsWith("4")) {
                    if (error.response.status.toString() === "428") {
                        setLoginError(AUTH_RESPONSES.unRegistered);
                    } else if (error.response.status.toString() === "429") {
                        onActiveSession();
                    } else {
                        setLoginError(AUTH_RESPONSES.unauthorized);
                    }
                } else {
                    setLoginError(AUTH_RESPONSES.genericError)
                }
            }
        });
    };

    const onResetEmail = values => {
        const param = values.resetEmail;
        store.dispatch(sendResetPasswordEmail('/reset/password', param)).then(() => {
            setForgotPasswordStep(2);
            setResetPwResponseMsg(AUTH_RESPONSES.checkEmail);
            setDisableSubmit(true);
            dispatch({...state, resetPwSent: true, renderResetPw: true});
        }, (error) => {
            setForgotPasswordStep(2);
            if (error.response && error.response.status) {
                if (error.response.status.toString().startsWith("4")) {
                    setResetPwResponseMsg(AUTH_RESPONSES.checkEmail);
                } else {
                    setResetEmailError(true);
                    setResetPwResponseMsg(AUTH_RESPONSES.genericError)
                }
            }
        });
    };

    const onResetPassword = values => {
        let body = {
            email: state.emailParam,
            resetPasswordCode: state.confirmationCodeParam,
            newPassword: values.newPassword
        };
        store.dispatch(sendNewPassword('/reset/password', body)).then(() => {
            setForgotPasswordStep(4);
        }, (error) => {
            if (error.response.status === 412) {
                setResetPasswordError(AUTH_RESPONSES.wrongPattern);
            } else if (error.response.status === 403) {
                setResetPasswordError(AUTH_RESPONSES.unauthorized);
            } else {
                setResetPasswordError(AUTH_RESPONSES.genericError)
            }
        });
    };

    const handleForgotPassword = () => {
        setForgotPasswordStep(1);
        setDisableSubmit(true)
    };

    const handleCheck  = (e) => {
        setTermsChecked(e.target.checked);
    };

    const handleChange  = (type) => {
        let disable = false;
        if (type === "login") {
            let email = getValues().email;
            let password = getValues().password;
            disable = checkEmptyStr(email) || checkEmptyStr(password);
            setDisableSubmit(disable);
            setLoginError(null);
        } else if (type === "resetEmail") {
            let resetEmail = getValues().resetEmail;
            disable = checkEmptyStr(resetEmail);
            setDisableSubmit(disable);
            setResetEmailError(null)
        } else if (type === "resetPassword" || type === "repeatPassword") {
            let newPassword = getValues().newPassword;
            let repeatNewPassword = getValues().repeatNewPassword;
            disable = checkEmptyStr(newPassword) || checkEmptyStr(repeatNewPassword);
            setDisableSubmit(disable);
            setResetPasswordError(null);
            if (type === "resetPassword") {
                setShowNewPwError(false);
            } else {
                setShowRepeatNewPwError(false);
            }
        }
    };

    const onActiveSession =()=> {
        setActiveSessionMsg(true);
        setTimeout(() => {
            setFadeActiveSessionMsg(true);
        }, 100);
    }

    const handleCancelPrevSession =()=> {
        store.dispatch(disconnectActiveSession('/disconnect/active/session')).then((resp) => {
            if (resp.value && resp.value.status && resp.value.status.toString() === '204') {
                hideSessionPopup();
                fetchUserProfile();
            }
        }, () => {
        });
    }

    const hideSessionPopup =()=> {
        setFadeActiveSessionMsg(false);
        setTimeout(() => {
            setActiveSessionMsg(false);
        }, 200)
    }

    const returnToLogin = () => {
        dispatch({
            ...state,
            renderResetPw: false
        });
    };

    return (
        <React.Fragment>
            <div className="auth__container">
                <div className={containerClsName}>
                    <React.Fragment>
                        <div className="auth__login--content">
                            <Link to={precedentPath ? precedentPath : '/'}
                                  className="btn__close">×</Link>
                            <article>
                                {forgotPasswordStep === 0 ?
                                    <form onSubmit={handleSubmit(onSubmitLogin)} className="auth__form">
                                        <div className="auth__login--top">
                                            <h4 className='auth__login--hdr'>Login</h4>
                                            <div className="bg-auth bg-user_login auth__login--icon">
                                            </div>
                                        </div>
                                        <input
                                            name="email"
                                            type="text"
                                            placeholder="Your professional email"
                                            autoComplete="on"
                                            className={(errors.email || (loginError && loginError.title) ? "auth__form--error": "") }
                                            ref={register({
                                                required: "Required field"
                                            })}
                                            onChange={()=> {handleChange("login")}}
                                        />
                                        {errors.email ?
                                            <span className="error-msg-txt">
                                        <i>
                                            {errors.email.message}
                                        </i>
                                    </span> : null}

                                        {loginError && loginError.title ?
                                            <span className='error-msg-txt'>
                                           <i>
                                                {loginError.title}
                                           </i>
                                       </span>
                                            :null}

                                        <div className="auth__reset--btn__forgot--wrapper">
                                            <input
                                                name="password"
                                                type="password"
                                                placeholder="Password"
                                                autoComplete="on"
                                                className={(errors.password ? "auth__form--error": "") }
                                                ref={register({
                                                    required: "Required field"
                                                })}
                                                onChange={()=> {handleChange("login")}}
                                            />
                                            <button className="auth__reset--btn auth__reset--btn__forgot"
                                                    type="button"
                                                    onClick={()=> {handleForgotPassword()}}>
                                                Forgot password?
                                            </button>
                                        </div>
                                        {errors.password ?
                                            <span className="error-msg-txt">
                                        <i>
                                            {errors.password.message}
                                        </i>
                                    </span> : null}
                                        <button className="auth__submit--btn auth__submit--btn__login"
                                                type="submit"
                                                disabled={disableSubmit}
                                                onClick={()=> {
                                                    sendGaEvent({category: 'auth_action', action: GA_EVENTS.auth.login.native})
                                                }}>
                                            SUBMIT
                                        </button>
                                        <div className="auth__register--btn__container">
                                            No account?
                                            <Link to={{pathname: '/register', state: {from: pathName}}}
                                                  className="auth__register--btn">
                                                SIGN UP
                                            </Link>
                                            for a FREE Trial now!
                                        </div>
                                        <LoginLinkedin termsChecked={termsChecked} gaEvent='login' />
                                        <AcceptTerms termsChecked={termsChecked} handleCheck={handleCheck} type={'login'} />
                                    </form>:null}
                                {forgotPasswordStep === 1 ?
                                    <form onSubmit={handleSubmit(onResetEmail)} className="auth__form auth__forgot">
                                        <h4 className="auth__forgot--hdr">Did you forget your password?
                                        </h4>
                                        <h6>{`Let us send you an email\nwith the reset link.`}</h6>
                                        {!resetEmailError ?
                                            <React.Fragment>
                                                <div className="bg-auth bg-user_forgot auth__forgot--icon">
                                                </div>
                                                <input
                                                    name="resetEmail"
                                                    type="text"
                                                    placeholder="Your professional email"
                                                    autoComplete="on"
                                                    className={(errors.resetEmail ? "auth__form--error": "") }
                                                    ref={register({
                                                        required: "Required field"
                                                    })}
                                                    onChange={()=> {handleChange("resetEmail")}}
                                                />
                                                {errors.resetEmail ?
                                                    <span className="error-msg-txt">
                                                <i>
                                                    {errors.resetEmail.message}
                                                </i>
                                            </span> : null}
                                                <button className="auth__submit--btn auth__submit--btn__forgot" type="submit" disabled={disableSubmit}>
                                                    {"SUBMIT"}
                                                </button>
                                            </React.Fragment>:
                                            <React.Fragment>
                                                <Link to={precedentPath ? precedentPath : '/'}
                                                      className="btn__close"
                                                      type="button">
                                                    Close
                                                </Link>
                                            </React.Fragment>
                                        }
                                    </form>:null}
                                {forgotPasswordStep === 2 ?
                                    <div className="auth__forgot--fetched">
                                        <div className="auth__forgot--fetched--content">
                                            <div className="auth__forgot--fetched__left">
                                                <h3>
                                                    {resetPwResponseMsg.title}
                                                </h3>
                                                <p className='auth_step-2--paragraph'>
                                                    {resetPwResponseMsg.text ? resetPwResponseMsg.text: ''}
                                                </p>
                                            </div>
                                            <div className="auth__forgot--fetched__right">
                                                <div className="bg-auth bg-shadow auth__action--fetched__shadow"/>
                                                <div className={"bg-auth auth__action--fetched__laptop" + (
                                                    resetPwResponseMsg.type === "success" ? " bg-laptop": " bg-sad_laptop"
                                                )}/>
                                            </div>
                                        </div>
                                    </div>:null}
                                {forgotPasswordStep === 3 ?
                                    <form onSubmit={handleSubmit(onResetPassword)} className="auth__form">
                                        <h4 className='auth__reset--hdr'>Create a new password</h4>
                                        <div className="bg-auth bg-user_reset auth__reset--icon">
                                        </div>
                                        <div className="auth__pw--tltp">
                                            <input
                                                name="newPassword"
                                                type="password"
                                                placeholder="New password"
                                                autoComplete="on"
                                                className={((errors.newPassword && showNewPwError) || (resetPasswordError && resetPasswordError.title)) ? "auth__form--error": "" }
                                                ref={register({
                                                    required: "Required field",
                                                    pattern: {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,20})/,
                                                        message: "Follow password rules"
                                                    }
                                                })}
                                                onChange={()=> {handleChange("resetPassword")}}
                                            />
                                            <InfoTooltip id='password-popover-login'
                                                         icon='bg-info_img'
                                                         placement='bottom'
                                                         content={pwPopoverMsg()}/>
                                        </div>
                                        {errors.newPassword && showNewPwError ?
                                            <span className="error-msg-txt">
                                                <i>
                                                    {errors.newPassword.message}
                                                </i>
                                            </span> : null}
                                        {resetPasswordError && resetPasswordError.title ?
                                            <span className='error-msg-txt'>
                                               <i>
                                                    {resetPasswordError.title}
                                               </i>
                                            </span>
                                            :null}
                                        <input
                                            name="repeatNewPassword"
                                            type="password"
                                            placeholder="Retype your password"
                                            autoComplete="on"
                                            className={(errors.repeatNewPassword && showRepeatNewPwError) ? "auth__form--error": "" }
                                            ref={register({
                                                required: "Required field",
                                                validate: (value) => {
                                                    return value === watch("newPassword") || "The passwords do not match"
                                                }
                                            })}
                                            onChange={()=> {handleChange("repeatPassword")}}
                                        />
                                        {errors.repeatNewPassword && showRepeatNewPwError ?
                                            <span className="error-msg-txt">
                                                <i>
                                                    {errors.repeatNewPassword.message}
                                                </i>
                                            </span>
                                            : null}
                                        <button className="auth__submit--btn auth__submit--btn__reset" type="submit"
                                                disabled={disableSubmit}
                                                onClick={()=> {
                                                    setShowNewPwError(true);
                                                    setShowRepeatNewPwError(true);
                                                }}>Submit</button>
                                    </form>
                                    :null}
                                {forgotPasswordStep === 4 ?
                                    <div className="auth__form">
                                        <h4>The password was changed successfully!</h4>
                                        <div className="bg-auth bg-user_login auth__reset-fetched--icon">
                                        </div>
                                        <Link to={'/login'}
                                              className="auth__submit--btn auth__submit--btn__finish-reset"
                                              type="button"
                                              onClick={returnToLogin}>
                                            LOGIN
                                        </Link>
                                    </div>:null}
                            </article>
                        </div>
                    </React.Fragment>
                </div>
            </div>
            {activeSessionMsg ?
                <div className={'auth__active-session-msg' +
                (fadeActiveSessionMsg ? ' auth__active-session-msg--fade-in': ' auth__active-session-msg--fade-out')}>
                    <section>
                        <article>
                            <p>{'There is another session active with your account.'}</p>
                            <p>{'Do you want to disconnect the other session and continue with a new one?'}</p>
                        </article>
                        <div className='auth__active-session-msg--btns'>
                            <button className='action__btn auth__active-session-msg--cancel'
                                    onClick={()=> {
                                        sendGaEvent({category: 'active_session_popup', action: GA_EVENTS.session.keep})
                                        hideSessionPopup();
                                    }}>
                                {'Cancel'}
                            </button>
                            <button className='action__btn auth__active-session-msg--proceed'
                                    onClick={()=> {
                                        sendGaEvent({category: 'active_session_popup', action: GA_EVENTS.session.disconnect})
                                        handleCancelPrevSession()
                                    }}>
                                {'Yes'}
                            </button>
                        </div>
                    </section>
                </div>
                :null}
        </React.Fragment>
    );
};

export const LoginPageContent = withRouter(LoginPageContentComponent);


