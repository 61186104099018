import React from 'react';
import {LoginPageContent} from './LoginPageContent';

const LoginLazyHelper =(props)=>  {
    const {update} = props;
    return (
        <React.Fragment>
            <LoginPageContent update={update} />
        </React.Fragment>
    );
};

export default LoginLazyHelper;
