import React from 'react';
import {Link} from "react-router-dom";
import {sendGaEvent} from "../../lib/utils";


const PromptItem = (props) => {
    const {content, showAltLink, gaEvent} = props;
    return (
        <article>
            <h4>{content.title}</h4>
            {content.link.type === 'route' ?
                <Link to={ content.link.stateFrom ?
                    {
                        pathname: content.link.address,
                        state:
                        {
                            from: content.link.stateFrom
                        }
                    }:
                    content.link.address}
                  onClick={()=> {
                  sendGaEvent(gaEvent)}}>
                    {content.link.text}
                </Link>:
                <a href={!showAltLink ? content.link.address: content.link.alt}
                   rel="noopener noreferrer"
                   target='_blank'
                   onClick={()=> {
                       sendGaEvent(gaEvent)}}>
                    {content.link.text}
                </a>
            }
            {content.note ?
                <p>
                    {content.note}
                </p>:
            null}
        </article>
    );
}

export default PromptItem;
