import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {PricingMainContent} from "./PricingMainContent";

const PricingPageContentComponent = (props) =>  {
    const {location} = props;
    const [enableFree, setEnableFree] = useState(null);

    const user = useSelector(state => state.userReducer);


    useEffect(() => {
        if (location && location.state && location.state.from === "confirmation") {
            setEnableFree(true);
        } else {
            if (!user.profile.fetching)
                if (!user.userLoggedIn) {
                    setEnableFree(true);
                } else {
                    setEnableFree(false);
                }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location, user.profile]);


    return (
        <div className="pricing">
            <hgroup>
                <h1 className='page__title'>PRICING & PLANS</h1>
                <h2 className='page__subtitle page__subtitle--pricing'>
                    Pricing for all the FINaplo Financial Messages products and services. We offer monthly fee for the online service and an annual fee for the downloadable SDKs. Also, check our Partnership Program.
                </h2>
            </hgroup>
            <React.Fragment>
                <section className={"pricing__container" + (enableFree ? " enable-free-plan": " disable-free-plan" )}>
                    <PricingMainContent showFreeBtn={enableFree} />
                </section>
            </React.Fragment>
        </div>
    );
};

export const PricingPageContent =  withRouter(PricingPageContentComponent);
