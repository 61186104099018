import React, {useState, useEffect, lazy, Suspense} from 'react';
import {withRouter} from "react-router-dom";
import JSONTree from 'react-json-tree';
import {
    arrToObjIteration,
    checkEmptyStr,
    checkGenericServerError,
    copyToClipBoard, sendGaEvent,
    unparseableMsgResp
} from "../../../../lib/utils";
import {
    FINAPLO_PLAYLIST,
    GA_EVENTS,
    ROUTE_PATHS,
    TREE_THEME
} from '../../../../lib/constants';
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";

const CodeMirror = lazy(() =>
    import("react-codemirror2").then(module => ({default: module.Controlled}))
);

function ParsedMsgRendererComponent(props) {
    const {respObject, userPermission, location} = props;
    const [respContent, setRespContent] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [isResultCode, setIsResultCode] = useState(false);
    const [codeMode, setCodeMode] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const [usrPerm, setUsrPerm] = useState(null);
    const [pathName, setPathName] = useState(null);

    useEffect(()=> {
        if (location && location.pathname) {
            setPathName(location.pathname);
        }
    }, [location]);

    useEffect(()=> {
        if (respObject) {
            const xml2js = require('xml2js');
            let isTransReq = respObject.reqType.startsWith('translate');
            let isEnvelopeReq= respObject.reqType.toLowerCase().includes('envelope');
            let isRecallReq=   respObject.reqType.toLowerCase().includes('request_recall');
            let isReturnReq = respObject.reqType.toLowerCase().includes('return_payment');
            let isRecallResp = respObject.reqType.toLowerCase().includes('recall_response');
            let isResCode = isTransReq || isEnvelopeReq || isRecallReq || isReturnReq || isRecallResp;
            let codeInResult = isResCode ? (respObject.reqType.includes('Mx') ? 'swift-mt': 'xml' ): null;
            setCodeMode(codeInResult);
            setIsResultCode(isResCode);
            setIsValid(respObject.validity);
            setIsCopied(false);
            let tmpRespContent =  respObject.hideRespArea ? respObject.content:
                (respObject.validity === 'valid' ?
                (!isResCode ? parseSync(respObject.content, xml2js) : respObject.content) :
                respObject.content);
                setRespContent(tmpRespContent);
        }
    }, [respObject]);

    useEffect(()=> {
        setUsrPerm(userPermission);
    }, [userPermission]);

    function parseSync (xml,xml2js) {
        let error = null;
        let json = null;
        xml2js.parseString(xml, function (innerError, innerJson) {
            error = innerError;
            json = innerJson;
        });
        if (error) {
            throw error;
        }
        if (!error && !json) {
            throw new Error('Something went wrong.');
        }
        arrToObjIteration(json);
        return json;
    }

    const copyClicked = () => {
        copyToClipBoard(respContent);
        setIsCopied(true);
    }

    return (
        <React.Fragment>
            <div className={'right__col--inner ' + (respObject && !respObject.hideRespArea  ? ' right__col--inner__result' : ' right__col--inner__default')}>
                {respObject ?
                    <React.Fragment>
                        {!respObject.hideRespArea ?
                            <React.Fragment>
                                <div className='right__col--result'>{"RESULT" + (isResultCode ? ":": "" ) + (respObject.respType ? " " + respObject.respType: '')}
                                </div>
                                <article className={("msg-" + respObject.validity) +
                                    (isResultCode && isValid === 'valid' ? ' code__response' : ' parse__response') +
                                    (codeMode === 'swift-mt' ? ' swift-mt__syntax': '')
                                }>
                                    {isValid === 'valid' && !isResultCode ?
                                        <JSONTree data={respContent}
                                                  hideRoot={true}
                                                  theme={TREE_THEME}
                                                  getItemString={() => null}
                                                  shouldExpandNode={()=>{return true}}
                                                  labelRenderer={([key]) => <b>{key}</b>}
                                        />
                                        :null}
                                         {isValid === 'valid' && isResultCode && codeMode ?
                                             <React.Fragment>
                                                <Button className='code__response--copy'
                                                     onClick={()=> {
                                                         copyClicked();
                                                     }}>
                                                 {!isCopied ? 'Copy' : 'Copied!'}
                                                </Button>

                                                <div className='code__response--content'>
                                                    <Suspense fallback={<div style={{height:"384px",backgroundColor:"#1E1E1E"}}/>}>
                                                        <CodeMirror
                                                            className={"xml-codemirror custom-codemirror"}
                                                            value={respContent}
                                                            readOnly={true}
                                                            options={{
                                                                mode: codeMode,
                                                                theme: 'checker-code',
                                                                lineNumbers: false,
                                                                lineWrapping: true,
                                                                cursorHeight: 0
                                                            }}
                                                        />
                                                    </Suspense>
                                                </div>
                                             </React.Fragment>
                                        :null}
                                    {isValid && isValid.includes('invalid') ?
                                        <ul>
                                            {respContent.map((errorItem, idx) => {
                                                if ((errorItem === 'Message could not be parsed' || errorItem === 'Unsupported Message Type' || errorItem === 'Message could not be translated') && respObject.msgType) {
                                                    return unparseableMsgResp(respObject.msgType)
                                                } else {
                                                    return errorItem.length > 0 ?
                                                        errorItem :
                                                        <li key={'outter-code-' + idx}>
                                                            {errorItem.errorCode ? 'Code: ' + errorItem.errorCode : null}
                                                            {checkEmptyStr(errorItem.message) && checkGenericServerError(errorItem)  ? 'Something is very wrong, but it is not your fault! We are working on it, please come back soon :)' : null}
                                                            <ul>
                                                                {errorItem.tagName ?
                                                                    <li key={'inner-tag-' + idx}> {'Tag: ' + errorItem.tagName}</li> : null}
                                                                {errorItem.occurs ?
                                                                    <li key={'inner-occur-' + idx}>{'Occurrence: ' + errorItem.occurs}</li> : null}
                                                                {errorItem.line ?
                                                                    <li key={'inner-line-' + idx}>{'Line: ' + errorItem.line}</li> : null}
                                                                {errorItem.description ?
                                                                    <li key={'inner-descr-' + idx}>{'Error: ' + errorItem.description}</li> : null}
                                                                {errorItem.errorDescription ?
                                                                    <li key={'inner-err-descr-' + idx}>{'Error: ' + errorItem.errorDescription}</li> : null}
                                                            </ul>
                                                        </li>
                                                }
                                             })}
                                        </ul>:null}
                                    {isValid === 'error' ?
                                        <div className='msg-invalid'>
                                            {respContent.toString()}
                                        </div>:null}
                                </article>
                            </React.Fragment>:null}
                </React.Fragment>:null}
                {!respObject || (respObject && respObject.hideRespArea) ?
                    <div className={"right__col--banner"}>
                        <div className={"right__col--banner__top" + (usrPerm === 'unAuth' ? " right__col--banner__top-align-right" : " right__col--banner__top-align-left")}>
                            {
                                usrPerm === 'unAuth' ?
                                    <Link to={{pathname: '/register', state: {from: pathName}}}
                                          className='right__col--banner__link'>
                                        <div className="right__col--banner__bubble right__col--banner__bubble--arrow right__col--banner__bubble--arrow__right">
                                            <div className="bg-sprites bg-paymentcomponents">
                                            </div>
                                        </div>
                                        <h3 className="right__col--banner__title">
                                            {`Sign up to the FREE trial. Edit, validate and translate online your messages.`}</h3>
                                        <div className="right__col--banner__caption">
                                            {`No credit card required`}</div>
                                    </Link>:null
                            }
                            {
                                usrPerm === 'freeActive' || usrPerm === 'freeInactive' || usrPerm === 'unlimitedInactive' ?
                                    <Link to={'/' + ROUTE_PATHS['pricing']} className='right__col--banner__link'>
                                        <div className="right__col--banner__bubble right__col--banner__bubble--arrow right__col--banner__bubble--arrow__left">
                                            <div className="bg-sprites bg-paymentcomponents">
                                            </div>
                                        </div>
                                        <h3 className="right__col--banner__title">
                                            {`Upgrade now for unlimited validations and SWIFT MT <> Translations`}
                                        </h3>
                                    </Link>:null
                            }
                            {
                                usrPerm === 'unlimitedActive' ?
                                    <Link to={'/' + ROUTE_PATHS['api']} className='right__col--banner__link'>
                                        <div className="right__col--banner__bubble right__col--banner__bubble--arrow right__col--banner__bubble--arrow__left">
                                            <div className="bg-sprites bg-paymentcomponents">
                                            </div>
                                        </div>
                                        <h3 className="right__col--banner__title">
                                            {'Now, all features are also available through an API. Get your API key!'}
                                        </h3>
                                    </Link>:null
                            }
                        </div>
                        {
                            usrPerm === 'unAuth' ?
                                <div className='right__col--banner__bottom'>
                                    <p>{'Do you want a quick preview of FINaplo Online features?'}</p>
                                    <a href={FINAPLO_PLAYLIST}
                                       key='finaplo-youtube-list-checker'
                                       className="btn__generic--blue-hover"
                                       rel="noopener noreferrer"
                                       target="_blank"
                                       onClick={()=> {
                                           sendGaEvent({category: 'finaplo_playlist_link',
                                               action: GA_EVENTS.online.playlist}
                                           )}}>
                                        <i/>
                                        <span>{'See Videos'}</span>
                                    </a>
                                </div>:null
                        }
                    </div>:null
                }
            </div>
        </React.Fragment>
    );
}

export const ParsedMsgRenderer = withRouter(ParsedMsgRendererComponent);
