import React, {useState} from 'react';
import {withRouter} from "react-router-dom";
import {Collapse} from "react-bootstrap";

const FaqComponent = (props) =>  {
    const {faqContent, componentKey, showTitle} = props;
    const [openAccordion, setOpenAccordion,] = useState(null);

    const renderFaqContent = (el, idx) => {
        let content = [];
        let key = Object.keys(el)[0];
        switch(key) {
            case 'accordionTitle':
                let isOpen = openAccordion === ('open-accordion-' + (idx + 1));
                content.push(
                    <div className='faq__accordion--title' key={componentKey + '-' + key + '-' + idx}
                         onClick={()=> {
                             if (isOpen) {
                                 setOpenAccordion(null);
                             } else {
                                 setOpenAccordion('open-accordion-'+ (idx + 1))}}
                         }>
                        <h5>{el[key]}</h5>
                        <div className={"faq__accordion--title__symbol " + (isOpen ? 'opened' : 'closed')}
                             key={componentKey + '-' + key + '-inner-' + idx}>
                            <span className="horizontal"></span>
                            <span className="vertical"></span>
                        </div>
                    </div>
                );
                break;
            case 'accordionText':
                content.push(
                    <Collapse in={openAccordion === ('open-accordion-' + idx)}
                              key={'accordion-collapse-' + componentKey + '-' + key + '-' + idx}
                              dimension= "height"
                              className='faq__accordion--text'>
                        <article key={'accordion-paragraph-' + componentKey + '-' + key + '-' + idx}>
                            {el[key].map((p, index)=> {
                                return <p key={'accordion-paragraph-' + componentKey + '-' + key + '-' + index}>
                                    {p['paragraph']}
                                </p>;
                            })}
                        </article>
                    </Collapse>
                );
                break;
            default:
                content.push(<br key={key + '-' + idx } />);
        }

        return content;
    };

    return (
        <article className='faq__content' key={componentKey + '-article'}>
            {
                showTitle ? <h4>FAQs</h4>: null
            }
            <div className='faq__item' key={componentKey + '-article-inner'}>
                {faqContent.map((el, idx) => {
                    return renderFaqContent(el, idx)
                })}
            </div>
        </article>
    );
}

export const Faq =  withRouter(FaqComponent);

