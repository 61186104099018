import {makeRequest} from "../api/api-client";


export const sendEmailComment = (path, msg) => {
    if (process.env.NODE_ENV === "production" ){
        path = process.env.SERVER_URL + path;
    }
    msg = {payload: msg};
    const config = { headers: {'Content-Type': 'application/json'}};
    return makeRequest(path, "POST", msg, config, "SEND_EMAIL_COMMENT");
};

